import React from "react";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import { Snackbar } from "@material-ui/core";
import { connect } from "react-redux";
import { setAlert, AppState } from "../../../store/reducer";

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export interface IAlertSnackBarProps {
  setAlert: any;
}

interface IAlertSnackProps extends IAlertSnackBarProps {
  message: string;
  open: boolean;
}

const AlertSnackBar = (props: IAlertSnackProps) => {
  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    props.setAlert("");
  };

  return (
    <Snackbar open={props.open} autoHideDuration={6000} onClose={handleClose}>
      <Alert onClose={handleClose} severity="error">
        {props.message}
      </Alert>
    </Snackbar>
  );
};

const mapStateToProps = (state: AppState) => ({
  message: state.Alert.message,
  open: state.Alert.open,
});

const mapDispatchToProps = { setAlert };

export default connect(mapStateToProps, mapDispatchToProps)(AlertSnackBar);
