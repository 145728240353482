import React, { useEffect, Fragment, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useCookies } from "react-cookie";
import { Routes } from "../../../../containers/Routes";
import { connect } from "react-redux";
import { CookieIdentifiers } from "../../../../models/Cookies";
import { makeStyles, Container, Card, Typography } from "@material-ui/core";
import theme from "../../../../assets/theme/theme";
import GameSelectionCard from "./GameSelectionCard";
import GamesApi from "../../../../services/GamesApi";
import { LocationModel } from "../../../../models/CrazyGolf/LocationModel";
import GamePlayers from "./GamePlayers.tsx/GamePlayers";
import { LocationApi } from "../../../../services/LocationApi";

const useStyles = makeStyles(() => ({
  root: {
    borderRadius: "0",
    border: "1px solid " + theme.palette.secondary.main,
    backgroundColor: theme.palette.background.default,
    margin: "0.5em 0",
  },
  title: {
    margin: "15px auto",
    textAlign: "center",
  },
}));

interface IGameSelectionPageProps {}

interface IGameSelectionPageParams {
  gameCode: string;
}

const GameSelection = (props: IGameSelectionPageProps) => {
  const classes = useStyles();
  let history = useHistory();
  const params: IGameSelectionPageParams = useParams();
  const [displayScanQr, setDisplayScanQr] = useState(false);

  const gameService = new GamesApi();
  const locationApi = new LocationApi();
  const [tracks, setTracks] = useState(new Array<LocationModel>());
  const [selectedTrack, setSelectedTrack] = useState("");

  const [cookies] = useCookies([CookieIdentifiers.SwingersCrazyGolf]);

  useEffect(() => {
    if (params.gameCode) {
      setSelectedTrack(params.gameCode);
    } else if (cookies[CookieIdentifiers.SwingersVenue]) {
      setDisplayScanQr(true);
    }
  }, [cookies, params]);

  useEffect(() => {
    async function getTracks() {
      locationApi
        .getGameLocations(cookies[CookieIdentifiers.SwingersVenue])
        .then((response) => {
          setTracks(response);
          setDisplayScanQr(false);
        })
        .catch((error) => {
          setDisplayScanQr(true);
        });
    }
    getTracks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectTrack = (selectedTrackUuid: string) => {
    setSelectedTrack(selectedTrackUuid);
  };

  const createGame = (players: Array<string>) => {
    async function create(players: Array<string>) {
      await gameService.create(selectedTrack, players).then((response) => {
        history.push(`/${Routes.PlayCrazyGolf}/${response.id}`);
      });
    }
    create(players);
  };

  return (
    <Fragment>
      {displayScanQr ? (
        <Container maxWidth="md">
          <Card variant="outlined" className={classes.root}>
            <Typography variant="h4" className={classes.title}>
              Please scan the qr from the game's entrance.
            </Typography>
          </Card>
        </Container>
      ) : selectedTrack ? (
        <Container maxWidth="md">
          <GamePlayers createGame={createGame}></GamePlayers>
        </Container>
      ) : tracks ? (
        <Container maxWidth="md">
          <Card variant="outlined" className={classes.root}>
            <Typography variant="h4" className={classes.title}>
              Select a track to play
            </Typography>
          </Card>
          {tracks.map((track, index) => (
            <GameSelectionCard
              key={index}
              location={track}
              selectLocation={selectTrack}
            ></GameSelectionCard>
          ))}
        </Container>
      ) : null}
    </Fragment>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(GameSelection);
