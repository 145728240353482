import { GameItem } from "../CrazyGolf/GameItem";
import { Pageable, Sorted } from "./Pageable";

export class GamesManagement {
  content!: GameItem[];
  pageable!: Pageable;
  totalPages: number = 0;
  totalElements: number = 0;
  last: boolean = false;
  number: number = 0;
  size!: number;
  sort!: Sorted;
  numberOfElements!: number;
  first!: boolean;
  empty!: boolean;

  constructor() {
    this.content = new Array<GameItem>();
  }
}
