import React from "react";
import { makeStyles, Grid, Card, Typography } from "@material-ui/core";
import theme from "../../../../assets/theme/theme";
import { Player } from "../../../../models/CrazyGolf/Player";

const useStyles = makeStyles(() => ({
  root: {
    padding: "0px",
  },
  mainCard: {
    borderRadius: "0",
    border: "0px solid " + theme.palette.secondary.main,
    backgroundColor: "transparent",
    margin: "0.5em 0",
  },
  title: {
    margin: "15px auto",
    textAlign: "center",
  },
  container: {
    display: "flex",
    width: "100%",
  },
  item: {
    flex: "1 1 0px",
    maxWidth: "20%",
  },
  cardTile: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  headerTile: {
    height: "50px",
    borderRadius: "0",
    border: "0.5px solid " + theme.palette.secondary.main,
    backgroundColor: theme.palette.primary.main,
  },
  halfHeaderTile: {
    height: "25px",
    borderRadius: "0",
    border: "0.5px solid " + theme.palette.secondary.main,
    backgroundColor: theme.palette.primary.main,
    display: "flex",
    flexDirection: "row",
  },
  transparentBackground: {
    height: "25px",
    backgroundColor: "transparent",
  },
  scoreTile: {
    height: "50px",
    borderRadius: "0",
    border: "0.5px solid " + theme.palette.secondary.main,
    backgroundColor: theme.palette.background.default,
  },
  cardContent: {
    flex: "1 1 0px",
  },
  holeTile: {
    height: "50px",
    borderRadius: "0",
    border: "0.5px solid " + theme.palette.secondary.main,
    backgroundColor: theme.palette.background.default,
    flexDirection: "row",
  },
  holeTileValue: {
    flex: "2 1 0",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.background.default,
    height: "100%",
  },
  holeTilePar: {
    flex: "1 1 0px",
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.background.default,
    height: "100%",
  },
  headerFooterTile: {
    fontFamily: theme.typography.h6.fontFamily,
    letterSpacing: "1px",
    textAlign: "center",
    overflow: "hidden",
  },
}));

interface IScorecardHoleProps {
  players: Array<Player>;
  hole: string;
  par: string;
  onTileClick: any;
}

const ScorecardHole = (props: IScorecardHoleProps) => {
  const classes = useStyles();

  return (
    <Grid item xs={12}>
      <Grid
        container
        justify="center"
        spacing={0}
        className={classes.container}
      >
        <Grid item className={classes.item}>
          <Card className={`${classes.holeTile} ${classes.cardTile}`}>
            <div className={`${classes.cardTile} ${classes.holeTileValue}`}>
              <Typography variant="h4">{props.hole}</Typography>
            </div>
            <div className={`${classes.cardTile} ${classes.holeTilePar}`}>
              {props.par}
            </div>
          </Card>
        </Grid>
        {props.players.map((player) => (
          <Grid key={player.id} item className={classes.item}>
            <Card
              className={`${classes.scoreTile} ${classes.cardTile}`}
              onClick={() => props.onTileClick(props.hole, player)}
            >
              {player.scorePerHoleMap &&
              player.scorePerHoleMap.hasOwnProperty(props.hole)
                ? player.scorePerHoleMap[props.hole]
                : ""}
            </Card>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default ScorecardHole;
