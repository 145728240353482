import React, { Fragment } from "react";
import {
  Container,
  Typography,
  makeStyles,
  createStyles,
  Avatar,
  Card,
  CardContent,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { RuleContent } from "../../../../models/CrazyGolf/RuleContent";
import theme from "../../../../assets/theme/theme";
import HexButton from "../../../GenericElements/HexButton/HexButton";
import { Routes } from "../../../../containers/Routes";
import rulesLogo from "../../../../assets/icons/golf/crazy-golf-icon.svg";

const useStyles = makeStyles(() =>
  createStyles({
    ruleBulletNumber: {
      color: theme.palette.primary.main,
      backgroundColor: "transparent",
      border: "1px solid " + theme.palette.secondary.main,
    },
    root: {
      borderRadius: "0",
      border: "1px solid " + theme.palette.secondary.main,
      backgroundColor: theme.palette.background.default,
    },
    center: {
      textAlign: "center",
    },
    cardContent: {
      display: "flex",
      padding: "5px 16px",
      alignItems: "center",
    },
    itemDetails: {
      margin: "0 15px",
      textAlign: "justify",
    },
    rulesLogo: {
      height: "100px",
      width: "100px",
      margin: "15px auto",
    },
    title: {
      margin: "15px auto",
      textAlign: "center",
    },
  })
);

interface IRulesProps {
  handleContinue: any;
}

const Rules = (props: IRulesProps) => {
  let classes = useStyles();
  var ruleContent = new RuleContent("How to play", rulesLogo);

  ruleContent.rules.push(
    "Five minutes before it's time to tee-off, head to the caddie shack of your allocated course with your crazy golf team."
  );
  ruleContent.rules.push(
    "Here you'll be greeted by one of our caddies who will help you pick the perfect putter and might even provide a couple of hints or tricks!"
  );
  ruleContent.rules.push(
    "Once on the course, each person will have 6 shots per hole."
  );
  ruleContent.rules.push(
    "Each player must complete the hole before the next person plays."
  );
  ruleContent.rules.push(
    "If the ball goes in the sand or off the course, it's replaced at the point closest to where it went off"
  );
  ruleContent.rules.push(
    "An unplayable ball may be moved to the length of a clubhead to make it playable again."
  );
  ruleContent.rules.push(
    "No need to leave the fairway if you are feeling thirsty, place your order with our caddies and we'll bring the drinks to you."
  );
  ruleContent.rules.push(
    "This is a putting game, for safety, please do not swing the club above knee height."
  );

  let history = useHistory();
  const goToGames = () => {
    if (props.handleContinue) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      props.handleContinue();
      return;
    }
    history.push(`/${Routes.GamesOverview}`);
  };
  return (
    <Fragment>
      <Container maxWidth="md">
        <Card variant="outlined" className={classes.root}>
          <div>
            <Avatar
              alt={ruleContent.title}
              src={ruleContent.logo}
              className={classes.rulesLogo}
            />
            <Typography variant="h5" className={classes.title}>
              {ruleContent.title}
            </Typography>
          </div>
          {ruleContent.rules.map((rule, index) => (
            <CardContent className={classes.cardContent} key={index}>
              <Avatar className={classes.ruleBulletNumber}>{index + 1}</Avatar>
              <div className={classes.itemDetails}>
                <div>
                  <Typography
                    component="span"
                    variant="body2"
                    color="textPrimary"
                  >
                    {rule}
                  </Typography>
                </div>
              </div>
            </CardContent>
          ))}
        </Card>
        <div className={classes.center}>
          <HexButton variant="contained" color="primary" onClick={goToGames}>
            OK, I got it
          </HexButton>
        </div>
      </Container>
    </Fragment>
  );
};

export default Rules;
