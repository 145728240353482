import React, { useState, useEffect } from "react";
import { TransitionProps } from "@material-ui/core/transitions/transition";
import {
  Slide,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  makeStyles,
} from "@material-ui/core";
import { Player } from "../../../../models/CrazyGolf/Player";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import theme from "../../../../assets/theme/theme";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles(() => ({
  root: {
    "& .MuiPaper-root": {
      backgroundColor: theme.palette.background.default,
    },
  },
  dialogContent: {
    textAlign: "center",
  },
}));

interface IScoreDialogProps {
  player: Player;
  hole: string;
  initialScore: number;
  isOpen: boolean;
  handleClose: any;
  handleSaveAndClose: any;
}

const ScoreDialog = (props: IScoreDialogProps) => {
  const classes = useStyles();

  const [score, setScore] = useState(1);

  const increase = () => {
    setScore(score + 1);
  };

  const decrease = () => {
    setScore(score - 1);
  };

  useEffect(() => {
    setScore(props.initialScore);
  }, [props.initialScore]);

  const handleClose = () => {
    props.handleClose();
    setScore(1);
  };

  const handleSaveAndClose = () => {
    let savedScore = score;
    props.handleSaveAndClose(savedScore);
    setScore(1);
  };
  return (
    <Dialog
      open={props.isOpen}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      className={classes.root}
    >
      <DialogTitle id="alert-dialog-slide-title">
        {`${props.player.name}'s score for hole ${props.hole}:`}
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Button color="secondary" onClick={decrease} disabled={score <= 1}>
          <RemoveCircleIcon></RemoveCircleIcon>
        </Button>
        <span>{score}</span>
        <span>
          <Button color="secondary" onClick={increase} disabled={score >= 6}>
            <AddCircleIcon></AddCircleIcon>
          </Button>
        </span>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSaveAndClose} color="primary">
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ScoreDialog;
