import React from "react";
import {
  Card,
  CardActionArea,
  CardContent,
  Avatar,
  Typography,
  makeStyles,
} from "@material-ui/core";
import TrackLogoService from "../../../../services/TrackLogoService";
import theme from "../../../../assets/theme/theme";
import { LocationModel } from "../../../../models/CrazyGolf/LocationModel";

const useStyles = makeStyles(() => ({
  root: {
    borderRadius: "0",
    border: "1px solid " + theme.palette.secondary.main,
    backgroundColor: theme.palette.background.default,
    margin: "0.5em 0",
  },
  cardContent: {
    padding: "5px 16px",
  },
  title: {
    margin: "15px auto",
    textAlign: "center",
  },
  trackName: {
    fontWeight: 700,
    color: theme.palette.primary.main,
    textAlign: "center",
    lineHeight: "1.5",
  },
  avatarIcon: {
    height: "100px",
    width: "100px",
    margin: "1.5em auto",
  },
}));

interface IGameSelectionCardProps {
  location: LocationModel;
  selectLocation: any;
}

const GameSelectionCard = (props: IGameSelectionCardProps) => {
  const classes = useStyles();
  return (
    <Card variant="outlined" className={classes.root}>
      <CardActionArea onClick={() => props.selectLocation(props.location.uuid)}>
        <CardContent className={classes.cardContent}>
          <div>
            <Avatar
              src={new TrackLogoService().get(props.location.name)}
              className={classes.avatarIcon}
            />
          </div>
          <div>
            <Typography variant="h6" className={classes.trackName}>
              {props.location.name}
            </Typography>
          </div>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default GameSelectionCard;
