export class GameUpdate {
  playerId: number;
  hole: string;
  score: number;

  constructor(playerId: number, hole: string, score: number) {
    this.playerId = playerId;
    this.hole = hole;
    this.score = score;
  }
}
