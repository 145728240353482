import React from "react";
import {
  AppBar,
  Toolbar,
  withStyles,
  useScrollTrigger,
} from "@material-ui/core";
import logo from "../../assets/icons/general/swingers-header-logo.svg";
import classes from "./Header.module.css";
import { useHistory } from "react-router-dom";

const styles = (theme: any) => ({
  offset: theme.mixins.toolbar,
});

const Header = (props: any) => {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  const elevation = trigger ? 5 : 0;
  let history = useHistory();
  const goToRoot = () => {
    history.push("/");
  };

  return (
    <div className={classes.Root}>
      <AppBar elevation={elevation}>
        <Toolbar className={classes.Toolbar}>
          <img
            src={logo}
            className={classes.Logo}
            alt="logo"
            onClick={goToRoot}
          />
        </Toolbar>
      </AppBar>
      <div className={props.classes.offset} />
      <Toolbar className={classes.Toolbar} />
    </div>
  );
};

export default withStyles(styles)(Header);
