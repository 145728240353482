import React from "react";
import {
  Container,
  Typography,
  makeStyles,
  createStyles,
  Avatar,
  Card,
  CardContent,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import theme from "../../../assets/theme/theme";
import { Routes } from "../../../containers/Routes";
import HexButton from "../../GenericElements/HexButton/HexButton";
import { InformationContent } from "../../../models/Information/InformationContent";
import FoodAndDrinkIcon from "../../../assets/icons/information/foodAndDrinksIcon.svg";
import PhotoPodiumIcon from "../../../assets/icons/information/photoPodiumIcon.svg";
import MerchandiseIcon from "../../../assets/icons/information/merchandiseIcon.svg";
import Covid19SecureIcon from "../../../assets/icons/information/securityIcon.svg";
import CrazyGolfIcon from "../../../assets/icons/information/crazyGolfIcon.svg";
import { CookieIdentifiers } from "../../../models/Cookies";
import { useCookies } from "react-cookie";

const useStyles = makeStyles(() =>
  createStyles({
    ruleBulletNumber: {
      color: theme.palette.primary.main,
      backgroundColor: "transparent",
      border: "1px solid " + theme.palette.secondary.main,
    },
    root: {
      borderRadius: "0",
      border: "1px solid " + theme.palette.secondary.main,
      backgroundColor: theme.palette.background.default,
      margin: "0.5em 0",
    },
    center: {
      textAlign: "center",
    },
    cardContent: {
      display: "flex",
      padding: "5px 16px",
      alignItems: "center",
    },
    itemImage: {
      height: "50px",
      width: "50px",
      margin: "2px",
    },
    link: {
      color: theme.palette.text.primary,
      fontWeight: 700,
      textDecoration: "unset",
    },
    itemDetails: {
      margin: "0 0 15px 15px",
      textAlign: "justify",
    },
    rulesLogo: {
      maxWidth: "100%",
      maxHeight: "100%",
      height: "100px",
      width: "100px",
      margin: "15px auto",
    },
    title: {
      margin: "15px auto",
      textAlign: "center",
    },
  })
);

const Information = () => {
  let classes = useStyles();
  const [cookies] = useCookies([CookieIdentifiers.SwingersCrazyGolf]);

  let locationId: string = cookies[CookieIdentifiers.SwingersVenue] ?? "";

  var infoContent = new Array<InformationContent>();

  let foodNDrink =
    locationId.toLowerCase() === "5996e9d5-a611-49ea-af47-5ef3f22bf91b"
      ? "To order delicious food and drink please speak to a server or place your order through the WebApp by scanning the QR code on the table."
      : "To order delicious food and drink please speak to a server or place your order through the WebApp by scanning the QR code on the table.";
  let crazyGolf =
    locationId.toLowerCase() === "5996e9d5-a611-49ea-af47-5ef3f22bf91b"
      ? "When it’s time for you to start playing crazy golf, head to the course that you’ve been allocated on your scorecard, where our caddies will run through everything you need to know. You can score your game using this app, simply head back to the home screen and select ‘crazy golf scoring’ from the main menu."
      : "When it’s time for you to start playing crazy golf, head to your allocated course where our caddies will run through everything you need to know. You can score your game using this app, simply head back to the home screen and select crazy golf scoring from the main menu.";
  let photoPodium =
    locationId.toLowerCase() === "5996e9d5-a611-49ea-af47-5ef3f22bf91b"
      ? "Once you’ve completed the course and crowned a winner, head to our 3D photo podium and smile for the cameras before celebrating in style with a drink at the bar. Don’t forget to tag @swingersus in your social posts!"
      : "Once you've completed the course and crowned yourself winner, head to our 3D photo podium and smile for the camera before celebrating in style with a drink at the bar. Don't forget to tag @swingersldn in your social!";
  let Merchandise =
    locationId.toLowerCase() === "5996e9d5-a611-49ea-af47-5ef3f22bf91b"
      ? "Look the part on and off the crazy golf course with our Swingers merchandise available to purchase at reception."
      : "Look the part on and off the crazy golf course with our Swingers merchandise available to purchase at reception.";
  let Covid =
    locationId.toLowerCase() === "5996e9d5-a611-49ea-af47-5ef3f22bf91b"
      ? 'We’ve put in place procedures to ensure we can operate safely including: hand sanitiser stations, contactless payment and regular cleaning of crazy golf balls and putters. To find out more about what we’re doing to keep you safe <a href="https://swingers.club/us/locations/washington/covid" class=${classes.link}>click here</a>.'
      : 'We’ve put in place procedures to ensure we can operate safely including: hand sanitiser stations, contactless payment and regular cleaning of crazy golf balls and putters. To find out more about what we’re doing to keep you safe <a href="https://swingers.club/uk/covid" class=${classes.link}>click here</a>.';

  infoContent.push(
    new InformationContent("Food & Drink", FoodAndDrinkIcon, foodNDrink)
  );
  infoContent.push(
    new InformationContent("Crazy Golf", CrazyGolfIcon, crazyGolf)
  );
  infoContent.push(
    new InformationContent("Photo Podium", PhotoPodiumIcon, photoPodium)
  );
  infoContent.push(
    new InformationContent("Merchandise", MerchandiseIcon, Merchandise)
  );
  infoContent.push(
    new InformationContent("COVID-19 Secure", Covid19SecureIcon, Covid)
  );

  let history = useHistory();
  const onHowToPlayClick = () => {
    history.push(`/${Routes.Rules}`);
  };
  return (
    <Container maxWidth="md">
      <Card variant="outlined" className={classes.root}>
        <Typography variant="h4" className={classes.title}>
          Information
        </Typography>
      </Card>
      <Card variant="outlined" className={classes.root}>
        {infoContent.map((info, index) => (
          <CardContent className={classes.cardContent} key={index}>
            <Avatar
              alt={info.title}
              src={info.logo}
              className={classes.itemImage}
            />
            <div className={classes.itemDetails}>
              <Typography variant="h6">{info.title}</Typography>
              <div>
                <Typography
                  component="span"
                  variant="body2"
                  color="textPrimary"
                >
                  <div dangerouslySetInnerHTML={{ __html: info.description }} />
                </Typography>
              </div>
            </div>
          </CardContent>
        ))}
      </Card>
      <div className={classes.center}>
        <HexButton
          variant="contained"
          color="primary"
          onClick={onHowToPlayClick}
        >
          How to play
        </HexButton>
      </div>
    </Container>
  );
};

export default Information;
