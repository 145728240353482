import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Routes } from "../../../containers/Routes";
import { useFormik, FormikHelpers } from "formik";
import {
  Card,
  CardContent,
  makeStyles,
  TextField,
  FormControl,
  FormLabel,
  Typography,
  Container,
} from "@material-ui/core";
import HexButton from "../../GenericElements/HexButton/HexButton";
import FormConfirmModal from "../../GenericElements/FormConfirmModal/FormConfirmModal";
import theme from "../../../assets/theme/theme";
import FeedbackFormQuestion from "./FeedbackFormQuestion/FeedbackFormQuestion";
import { setAlert } from "../../../store/reducer";
import { connect } from "react-redux";
import { IAlertSnackBarProps } from "../../GenericElements/AlertSnackBar/AlertSnackBar";
import { useCookies } from "react-cookie";
import { CookieIdentifiers } from "../../../models/Cookies";
import { FeedbackFormService } from "../../../services/FeedbackFormApi";
import { FeedbackFormRequest } from "../../../models/Feedback/FeedbackFormRequest";
import feedbackIcon from "../../../assets/icons/home/feedbackIcon.svg";
import { FeedbackEntry } from "../../../models/Feedback/FeedbackEntry";

const useStyles = makeStyles(() => ({
  root: {
    borderRadius: "0",
    border: "1px solid " + theme.palette.secondary.main,
    backgroundColor: theme.palette.background.default,
    padding: "15px auto",
  },
  center: {
    textAlign: "center",
  },
  cardContent: {
    padding: "5px 16px",
  },
  formControl: {
    display: "block",
    margin: "0",
  },
  inputLabelColor: {
    color: "currentColor",
  },
  label: {
    color: theme.palette.primary.main,
    justifyContent: "center",
    textAlign: "center",
    paddingTop: "15px",
  },
}));

interface FeedbackFormValues {
  experienceQuestion: number;
  serviceQuestion: number;
  crazyGolfQuestion: number;
  foodAndDrinksQuestion: number;
  returnQuestion: number;
  additionalInfo: string;
}

interface IFeedbackFormProps extends IAlertSnackBarProps {}

const FeedbackForm = (props: IFeedbackFormProps) => {
  const classes = useStyles();
  let history = useHistory();
  const [displayForm, setDisplayForm] = useState(true);
  const [cookies] = useCookies([CookieIdentifiers.SwingersCrazyGolf]);

  const feedbackFormService = new FeedbackFormService();

  const handleFormSubmit = async (values: FeedbackFormValues) => {
    let location = cookies[CookieIdentifiers.SwingersVenue];
    if (location) {
      let feedbackFormRequest = new FeedbackFormRequest();
      feedbackFormRequest.entries = Object.entries(values)
        .map((pair) => {
          let entry = feedbackFormService.getFormEntries()[pair[0]];
          if (entry) {
            entry.answer = pair[1];
            return entry;
          }
          return {} as FeedbackEntry;
        })
        .filter((x) => x.answer !== undefined);
      feedbackFormRequest.additionalInfo = values.additionalInfo;
      feedbackFormService.register(location, feedbackFormRequest);
      setDisplayForm(false);
    } else {
      props.setAlert(
        "Please scan the QR at the entrance before submitting the form"
      );
    }
  };

  const onPlayCrazyGolf = () => {
    history.push(`/${Routes.Rules}`);
  };
  const onOrderFoodAndDrinks = () => {
    if (cookies[CookieIdentifiers.SwingersVenueFood]) {
      window.location.href = cookies[CookieIdentifiers.SwingersVenueFood];
    } else {
      props.setAlert(
        "Please scan the QR on table in order to access the menu."
      );
    }
  };

  const validate = (values: FeedbackFormValues): any => {
    const errors: any = {};
    if (!values.experienceQuestion) {
      errors.experienceQuestion = "Required";
    }
    if (!values.serviceQuestion) {
      errors.serviceQuestion = "Required";
    }
    if (!values.crazyGolfQuestion) {
      errors.crazyGolfQuestion = "Required";
    }
    if (!values.foodAndDrinksQuestion) {
      errors.foodAndDrinksQuestion = "Required";
    }
    if (!values.returnQuestion) {
      errors.returnQuestion = "Required";
    }
    return errors;
  };

  const formik = useFormik({
    initialValues: {
      experienceQuestion: 0,
      serviceQuestion: 0,
      crazyGolfQuestion: 0,
      foodAndDrinksQuestion: 0,
      returnQuestion: 0,
      additionalInfo: "",
    },
    validate,
    onSubmit: async (
      values: FeedbackFormValues,
      { setSubmitting }: FormikHelpers<FeedbackFormValues>
    ) => {
      await handleFormSubmit(values);
      setSubmitting(false);
    },
  });

  return displayForm ? (
    <Container maxWidth="md">
      <Card variant="outlined" className={classes.root}>
        <CardContent className={classes.cardContent}>
          <form onSubmit={formik.handleSubmit}>
            <FeedbackFormQuestion
              label={
                feedbackFormService.getFormEntries()["experienceQuestion"]
                  .question
              }
              error={formik.errors.experienceQuestion}
              touched={formik.touched.experienceQuestion}
              {...formik.getFieldProps("experienceQuestion")}
            ></FeedbackFormQuestion>
            <FeedbackFormQuestion
              label={
                feedbackFormService.getFormEntries()["serviceQuestion"].question
              }
              {...formik.getFieldProps("serviceQuestion")}
              error={formik.errors.serviceQuestion}
              touched={formik.touched.serviceQuestion}
            ></FeedbackFormQuestion>
            <FeedbackFormQuestion
              label={
                feedbackFormService.getFormEntries()["crazyGolfQuestion"]
                  .question
              }
              {...formik.getFieldProps("crazyGolfQuestion")}
              error={formik.errors.crazyGolfQuestion}
              touched={formik.touched.crazyGolfQuestion}
            ></FeedbackFormQuestion>
            <FeedbackFormQuestion
              label={
                feedbackFormService.getFormEntries()["foodAndDrinksQuestion"]
                  .question
              }
              {...formik.getFieldProps("foodAndDrinksQuestion")}
              error={formik.errors.foodAndDrinksQuestion}
              touched={formik.touched.foodAndDrinksQuestion}
            ></FeedbackFormQuestion>
            <FeedbackFormQuestion
              label={
                feedbackFormService.getFormEntries()["returnQuestion"].question
              }
              {...formik.getFieldProps("returnQuestion")}
              error={formik.errors.returnQuestion}
              touched={formik.touched.returnQuestion}
            ></FeedbackFormQuestion>
            <FormControl className={classes.formControl}>
              <FormLabel component="legend" className={classes.label}>
                <Typography variant="subtitle1">
                  Is there anything else you want us to know?
                </Typography>
                <TextField
                  id="additionalInfo"
                  {...formik.getFieldProps("additionalInfo")}
                  fullWidth
                  margin="normal"
                  InputLabelProps={{
                    className: classes.inputLabelColor,
                  }}
                  variant="outlined"
                />
              </FormLabel>
            </FormControl>

            <div className={classes.center}>
              <HexButton
                variant="contained"
                color="primary"
                type="submit"
                disabled={formik.isSubmitting}
              >
                Submit
              </HexButton>
            </div>
          </form>
        </CardContent>
      </Card>
    </Container>
  ) : (
    <FormConfirmModal
      lineTwo="For submitting your feedback"
      lineThree="of the swingers experience!"
      playCrazyGolf={onPlayCrazyGolf}
      orderFoodAndDrinks={onOrderFoodAndDrinks}
      icon={feedbackIcon}
    ></FormConfirmModal>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = { setAlert };

export default connect(mapStateToProps, mapDispatchToProps)(FeedbackForm);
