import React from "react";
import { Player } from "../../../../models/CrazyGolf/Player";
import { makeStyles, Grid, Card, Typography } from "@material-ui/core";
import theme from "../../../../assets/theme/theme";

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    width: "100%",
  },
  item: {
    flex: "1 1 0px",
    maxWidth: "20%",
  },
  cardTile: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  scoreTile: {
    height: "50px",
    borderRadius: "0",
    border: "0.5px solid " + theme.palette.secondary.main,
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.background.default,
  },
  holeTile: {
    height: "50px",
    borderRadius: "0",
    border: "0.5px solid " + theme.palette.secondary.main,
    backgroundColor: theme.palette.background.default,
    flexDirection: "row",
  },
  holeTileValue: {
    flex: "2 1 0",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.background.default,
    height: "100%",
  },
  holeTilePar: {
    flex: "1 1 0px",
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.background.default,
    height: "100%",
  },
  headerFooterTile: {
    fontFamily: theme.typography.h6.fontFamily,
    letterSpacing: "1px",
    textAlign: "center",
    overflow: "hidden",
  },
}));

interface IScorecardTotalProps {
  players: Array<Player>;
}

const ScorecardTotal = (props: IScorecardTotalProps) => {
  const classes = useStyles();
  return (
    <Grid item xs={12}>
      <Grid
        container
        justify="center"
        spacing={0}
        className={classes.container}
      >
        <Grid item className={classes.item}>
          <Card className={`${classes.cardTile} ${classes.holeTile}`}>
            <div className={`${classes.cardTile} ${classes.holeTileValue}`}>
              <Typography variant="body2" className={classes.headerFooterTile}>
                Total
              </Typography>
            </div>
            <div className={`${classes.cardTile} ${classes.holeTilePar}`}>
              <Typography variant="body2" className={classes.headerFooterTile}>
                21
              </Typography>
            </div>
          </Card>
        </Grid>
        {props.players.map((player) => (
          <Grid key={player.id} item className={classes.item}>
            <Card className={`${classes.scoreTile} ${classes.cardTile}`}>
              <Typography variant="h6" className={classes.headerFooterTile}>
                {player.score}
              </Typography>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default ScorecardTotal;
