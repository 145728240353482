import React from "react";
import { Container, Avatar } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import HexButton from "../../GenericElements/HexButton/HexButton";
import { Routes } from "../../../containers/Routes";
import FoodAndDrinkIcon from "../../../assets/icons/home/foodAndDrinksIcon.svg";
import CrazyGolfIcon from "../../../assets/icons/home/crazyGolfIcon.svg";
import TrackAndTraceIcon from "../../../assets/icons/home/securityIcon.svg";
import InformationIcon from "../../../assets/icons/home/informationIcon.svg";
import FeedbackIcon from "../../../assets/icons/home/feedbackIcon.svg";
import { CookieIdentifiers } from "../../../models/Cookies";
import { useCookies } from "react-cookie";
import { IAlertSnackBarProps } from "../../GenericElements/AlertSnackBar/AlertSnackBar";
import { connect } from "react-redux";
import { setAlert } from "../../../store/reducer";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      height: "100%",
      alignItems: "center",
    },
    container: {
      display: "flex",
      height: "80%",
      minHeight: "400px",
      padding: "3rem 16px 16px 16px",
      width: "350px",
      justifyContent: "space-around",
      flexDirection: "column",
    },
    itemImage: {
      height: "60px",
      width: "60px",
      marginRight: "15px",
    },
    optionContent: {
      display: "flex",
      alignItems: "center",
    },
    hideContent: {
      display: "none !important",
    },
  })
);

interface IMainPageProps extends IAlertSnackBarProps {}

const MainPage = (props: IMainPageProps) => {
  const classes = useStyles();
  let history = useHistory();
  const [cookies] = useCookies([CookieIdentifiers.SwingersCrazyGolf]);

  let locationId: string = cookies[CookieIdentifiers.SwingersVenue] ?? "";

  let isDCLocal =
    locationId.toLowerCase() === "5996e9d5-a611-49ea-af47-5ef3f22bf91b"
      ? true
      : false;

  const onTrackAndTraceClick = () => {
    history.push(`/${Routes.TrackAndTrace}`);
  };
  const onOrderFoodClick = () => {
    if (cookies[CookieIdentifiers.SwingersVenueFood]) {
      window.location.href = cookies[CookieIdentifiers.SwingersVenueFood];
    } else {
      props.setAlert(
        "Please scan the QR on table in order to access the menu."
      );
    }
  };
  const onPlayCrazyGolfClick = () => {
    history.push(`/${Routes.GamesOverview}`);
  };
  const onFeedbackFormClick = () => {
    history.push(`/${Routes.FeedbackForm}`);
  };
  const onInformationClick = () => {
    history.push(`/${Routes.Information}`);
  };

  return (
    <div className={classes.root}>
      <Container className={classes.container}>
        <div className={classes.optionContent}>
          <Avatar
            alt="Menus & Ordering"
            src={FoodAndDrinkIcon}
            className={classes.itemImage}
          />
          <HexButton
            variant="contained"
            color="primary"
            onClick={onOrderFoodClick}
            fullWidth={true}
            size="large"
          >
            {"Menus & ordering"}
          </HexButton>
        </div>
        <div className={classes.optionContent}>
          <Avatar
            alt="Crazy Golf Scoring"
            src={CrazyGolfIcon}
            className={classes.itemImage}
          />
          <HexButton
            variant="contained"
            color="primary"
            onClick={onPlayCrazyGolfClick}
            fullWidth={true}
            size="large"
          >
            Crazy Golf Scoring
          </HexButton>
        </div>
        {/* <div className={(isDCLocal)?classes.hideContent : classes.optionContent}>
          <Avatar
            alt="Track & trace"
            src={TrackAndTraceIcon}
            className={classes.itemImage}
          />
          <HexButton
            variant="contained"
            color="primary"
            onClick={onTrackAndTraceClick}
            fullWidth={true}
            size="large"
          >
            Track & trace
          </HexButton>
        </div> */}
        <div className={classes.optionContent}>
          <Avatar
            alt="Information"
            src={InformationIcon}
            className={classes.itemImage}
          />
          <HexButton
            variant="contained"
            color="primary"
            onClick={onInformationClick}
            fullWidth={true}
            size="large"
          >
            Information
          </HexButton>
        </div>
        <div className={classes.optionContent}>
          <Avatar
            alt="Feedback"
            src={FeedbackIcon}
            className={classes.itemImage}
          />
          <HexButton
            variant="contained"
            color="primary"
            onClick={onFeedbackFormClick}
            fullWidth={true}
            size="large"
          >
            Feedback
          </HexButton>
        </div>
      </Container>
    </div>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = { setAlert };

export default connect(mapStateToProps, mapDispatchToProps)(MainPage);
