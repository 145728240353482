import { createMuiTheme } from "@material-ui/core/styles";
import backgroundImage from "../icons/general/swingers-background.png";

const themeDetails = {
  palette: {
    common: { black: "#000", white: "#fff" },
    background: { paper: "#fff", default: "#FFFAE7" },
    primary: {
      main: "#152D39",
      contrastText: "#FFFAE7",
    },
    secondary: {
      main: "#BCA25F",
      contrastText: "#434343",
    },
    error: {
      light: "#FF8185",
      main: "#EA6858",
      dark: "#C5483C",
      contrastText: "#FFF5F1",
    },
    text: {
      primary: "#333333",
      secondary: "#FFFAE7",
      disabled: "#6F5955",
      hint: "#574142",
    },
  },
  typography: {
    fontFamily: [`"Source Serif Pro"`, "sans-serif"].join(","),
    h1: {
      // letterSpacing: "2px",
      fontFamily: "'Oswald', sans-serif",
    },
    h2: {
      fontFamily: "'Oswald', sans-serif",
    },
    h3: {
      fontFamily: "'Oswald', sans-serif",
    },
    h4: {
      fontFamily: "'Oswald', sans-serif",
    },
    h5: {
      fontFamily: "'Oswald', sans-serif",
    },
    h6: {
      fontFamily: "'Oswald', sans-serif",
    },
    subtitle1: {
      fontFamily: "'Oswald', sans-serif",
    },
    subtitle2: {
      fontFamily: "'Oswald', sans-serif",
    },
    body1: {
      fontFamily: "'Source Serif Pro', serif",
    },
    body2: {
      fontFamily: "'Source Serif Pro', serif",
    },
    caption: {
      fontFamily: "'Source Serif Pro', serif",
    },
    overline: {
      fontFamily: "'Source Serif Pro', serif",
    },
    button: {
      fontFamily: "'Oswald', sans-serif",
      fontWeight: 400,
    },
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        body: {
          background: `url("${backgroundImage}")`,
          backgroundSize: "100%",
        },
      },
    },
  },
};

const theme = createMuiTheme(themeDetails);

export default theme;
