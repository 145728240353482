import React from "react";
import { Formik, Form, FieldArray, FormikHelpers, FormikProps } from "formik";
import {
  Card,
  Typography,
  CardContent,
  makeStyles,
  TextField,
  Button,
} from "@material-ui/core";
import theme from "../../../../../assets/theme/theme";
import HexButton from "../../../../GenericElements/HexButton/HexButton";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import keycloak from "../../../../../assets/keycloak/Keycloak";

const useStyles = makeStyles(() => ({
  root: {
    borderRadius: "0",
    border: "1px solid " + theme.palette.secondary.main,
    backgroundColor: theme.palette.background.default,
  },
  center: {
    textAlign: "center",
  },
  cardContent: {
    padding: "5px 16px",
  },
  playerEntry: {
    display: "flex",
    alignItems: "center",
  },
  textfieldEntry: {
    flex: "1 1 auto",
  },
  title: {
    margin: "15px auto",
    textAlign: "center",
  },
  inputLabelColor: {
    color: "currentColor",
  },
  invisible: {
    visibility: "hidden",
  },
}));

interface PlayersFormValues {
  players: Array<string>;
}

interface IGamePlayersProps {
  createGame: any;
}

const GamePlayers = (props: IGamePlayersProps) => {
  const classes = useStyles();

  const validate = (values: PlayersFormValues) => {
    const errors: any = {};
    const playersErrors = new Array<string>();
    values.players.forEach((player, index) => {
      if (!player) {
        playersErrors[index] = "Required";
      } else if (player.length > 20) {
        playersErrors[index] = "Must be 20 characters or less";
      }
      if (player.length < 3) {
        playersErrors[index] = "Name must be at least 3 characters long.";
      }
      if (!player.match("^[a-zA-Z_' -]*$") || /^[ _'-]+$/.test(player)) {
        playersErrors[index] = "Must be alphabetical characters";
      }
    });
    if (playersErrors.length) {
      errors.players = playersErrors;
    }
    return errors;
  };

  const handleFormSubmit = async (values: PlayersFormValues) => {
    props.createGame(values.players);
  };

  return (
    <Card variant="outlined" className={classes.root}>
      <div>
        <Typography variant="h5" className={classes.title}>
          Please add the names of who you're playing with:
        </Typography>
      </div>
      <CardContent className={classes.cardContent}>
        <Formik
          initialValues={{ players: [""] } as PlayersFormValues}
          validate={validate}
          onSubmit={(
            values: PlayersFormValues,
            { setSubmitting }: FormikHelpers<PlayersFormValues>
          ) => {
            handleFormSubmit(values);
            setSubmitting(false);
          }}
          render={(formikBag: FormikProps<PlayersFormValues>) => (
            <Form>
              <div className={classes.playerEntry}>
                <Button
                  color="secondary"
                  disabled={true}
                  className={classes.invisible}
                >
                  <RemoveCircleIcon fontSize="large"></RemoveCircleIcon>
                </Button>
                <TextField
                  className={classes.textfieldEntry}
                  label={`Name of player 1`}
                  value={(keycloak.tokenParsed as any).given_name}
                  margin="normal"
                  InputLabelProps={{
                    className: classes.inputLabelColor,
                  }}
                  variant="outlined"
                  disabled={true}
                />
              </div>
              <FieldArray
                name="players"
                render={(arrayHelpers) => (
                  <div>
                    {formikBag.values.players.map((player, index) => (
                      <div key={index} className={classes.playerEntry}>
                        <Button
                          color="secondary"
                          onClick={() => arrayHelpers.remove(index)}
                          disabled={formikBag.values.players.length < 2}
                        >
                          <RemoveCircleIcon fontSize="large"></RemoveCircleIcon>
                        </Button>
                        <TextField
                          className={classes.textfieldEntry}
                          name={`players[${index}]`}
                          label={`Name of player ${index + 2}`}
                          helperText={
                            formikBag.errors &&
                            formikBag.errors.players &&
                            formikBag.errors.players.length > index &&
                            formikBag.errors.players[index] &&
                            formikBag.touched.players
                              ? formikBag.errors.players[index]
                              : "Enter player's name"
                          }
                          margin="normal"
                          InputLabelProps={{
                            className: classes.inputLabelColor,
                          }}
                          variant="outlined"
                          onChange={formikBag.handleChange}
                          value={player}
                          error={
                            formikBag.errors &&
                            formikBag.errors.players &&
                            formikBag.errors.players.length > index &&
                            formikBag.errors.players[index] &&
                            formikBag.touched.players
                              ? true
                              : false
                          }
                        />
                      </div>
                    ))}
                    {formikBag.values.players.length < 3 ? (
                      <Button
                        color="secondary"
                        onClick={() => {
                          arrayHelpers.push("");
                        }}
                      >
                        <AddCircleIcon fontSize="large"></AddCircleIcon>
                      </Button>
                    ) : null}

                    <div className={classes.center}>
                      <HexButton
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={formikBag.isSubmitting}
                      >
                        Start Game
                      </HexButton>
                    </div>
                  </div>
                )}
              />
            </Form>
          )}
        />
      </CardContent>
    </Card>
  );
};

export default GamePlayers;
