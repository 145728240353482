import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Routes } from "../../../../containers/Routes";
import { connect } from "react-redux";
import { makeStyles, Card, Typography, Container } from "@material-ui/core";
import theme from "../../../../assets/theme/theme";
import { LocationModel } from "../../../../models/CrazyGolf/LocationModel";
import GameSelectionCard from "../../../Customer/PlayGolf/GameSelection/GameSelectionCard";
import { LocationApi } from "../../../../services/LocationApi";

const useStyles = makeStyles(() => ({
  root: {
    borderRadius: "0",
    border: "1px solid " + theme.palette.secondary.main,
    backgroundColor: theme.palette.background.default,
    margin: "0.5em 0",
  },
  title: {
    margin: "15px auto",
    textAlign: "center",
  },
}));

const LeaderboardLocations = () => {
  const classes = useStyles();
  let history = useHistory();

  const locationService = new LocationApi();
  const [venues, setVenues] = useState(new Array<LocationModel>());
  const [, setSelectedVenue] = useState("");

  useEffect(() => {
    async function getVenues() {
      locationService
        .getVenueLocations()
        .then((venues: Array<LocationModel>) => {
          setVenues(venues);
        })
        .catch(() => {});
    }
    getVenues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectVenue = (selectedVenueUuid: string) => {
    setSelectedVenue(selectedVenueUuid);
    history.push(`/${Routes.Leaderboard}/${selectedVenueUuid}`);
  };
  return (
    <Container maxWidth="md">
      <Card variant="outlined" className={classes.root}>
        <Typography variant="h4" className={classes.title}>
          Select a venue's leaderboard
        </Typography>
      </Card>
      {venues
        ? venues.map((track, index) => (
            <GameSelectionCard
              key={index}
              location={track}
              selectLocation={selectVenue}
            ></GameSelectionCard>
          ))
        : null}
    </Container>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LeaderboardLocations);
