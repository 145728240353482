import React, { useEffect, useState } from "react";
import { makeStyles, Container, Card, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useCookies } from "react-cookie";
import { Routes } from "../../../../containers/Routes";
import HexButton from "../../../GenericElements/HexButton/HexButton";
import GameOverviewItem from "./GameOverviewItem.tsx/GameOverviewItem";
import { GameItem } from "../../../../models/CrazyGolf/GameItem";
import GamesApi from "../../../../services/GamesApi";
import { CookieIdentifiers } from "../../../../models/Cookies";
import { connect } from "react-redux";
import { GameStatus } from "../../../../models/CrazyGolf/GameStatus";
import ConfirmationDialog from "../../../GenericElements/ConfirmationDialog/ConfirmationDialog";
import theme from "../../../../assets/theme/theme";

const useStyles = makeStyles(() => ({
  center: {
    textAlign: "center",
  },
  root: {
    borderRadius: "0",
    border: "1px solid " + theme.palette.secondary.main,
    backgroundColor: theme.palette.background.default,
    margin: "0.5em 0",
  },
  title: {
    margin: "15px auto",
    textAlign: "center",
  },
}));

const GamesOverview = () => {
  const classes = useStyles();
  let history = useHistory();

  const [isStartGameDialogOpen, setIsStartGameDialogOpen] = useState(false);
  const [gamesOverview, setGamesOverview] = useState(new Array<GameItem>());
  const [canStartNewGame, setCanStartNewGame] = useState(false);
  const [cookies] = useCookies([CookieIdentifiers.SwingersCrazyGolf]);
  const gameService = new GamesApi();

  useEffect(() => {
    async function getGamesOverview() {
      await gameService.getAll().then((response) => {
        response.sort((a: GameItem, b: GameItem) => {
          return b.createdDate.getTime() - a.createdDate.getTime();
        });
        setGamesOverview(response);
      });
    }
    getGamesOverview();
    if (cookies[CookieIdentifiers.SwingersVenue]) {
      setCanStartNewGame(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cookies]);

  const onStartGameClick = () => {
    if (gamesOverview.find((x) => x.status === GameStatus.Ongoing)) {
      setIsStartGameDialogOpen(true);
      return;
    }
    history.push(`/${Routes.GameSelection}`);
  };

  const handleConfirmStartGame = () => {
    setIsStartGameDialogOpen(false);
    history.push(`/${Routes.GameSelection}`);
  };

  const handleCancelStartGame = () => {
    setIsStartGameDialogOpen(false);
  };

  return (
    <Container maxWidth="md">
      <div className={classes.center}>
        {canStartNewGame ? (
          <HexButton
            variant="contained"
            color="primary"
            onClick={onStartGameClick}
            size="large"
          >
            Start game
          </HexButton>
        ) : (
          <Card variant="outlined" className={classes.root}>
            <Typography variant="h5" className={classes.title}>
              If you want to start a new game, <br />
              please scan the qr from the game's entrance.
            </Typography>
          </Card>
        )}
      </div>
      {gamesOverview.map((gameItem, index) => (
        <GameOverviewItem key={index} details={gameItem}></GameOverviewItem>
      ))}
      <ConfirmationDialog
        isOpen={isStartGameDialogOpen}
        handleCancel={handleCancelStartGame}
        handleConfirm={handleConfirmStartGame}
        title="Are you sure you want to start a new game?"
        message="You already have an ongoing game. Do you wish to finish it and start a new one?"
      ></ConfirmationDialog>
    </Container>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(GamesOverview);
