import React from "react";
import { makeStyles, createStyles, Container } from "@material-ui/core";
import theme from "../../../../assets/theme/theme";
import logo from "../../../../assets/icons/leaderboard/swinger-logo.svg";
import title from "../../../../assets/icons/leaderboard/Leaderboard-text.svg";
import trophy from "../../../../assets/icons/leaderboard/Leaderboard-throphy.svg";

const useStyles = makeStyles(() =>
  createStyles({
    containerRoot: {
      // marginTop: "4%",
      marginBottom: "10px",
      display: "flex",
      minWidth: "100%",
      padding: "0px 40px",
    },
    toolbar: {
      //   position: "absolute",
      //   top: "0",
      zIndex: 2000,
      width: "100%",
      backgroundColor: "transparent",
      // height: "275px",
      // marginLeft: "-16px",
      textAlign: "center",
      display: "block",
    },
    title: {
      // height: "5rem",
      // margin: "1rem 2rem",
      // alignSelf: "center",
      // //   top: "-200px",
      // zIndex: 2002,
      // position: "relative",
    },
  })
);

const LeaderboardHeader = (props: any) => {
  let classes = useStyles();
  return (
    <div className={classes.toolbar}>
      {/* <img
          src={logo}
          className={classes.logo}
          alt="logo"
          // onClick={goToRoot}
          />
        <img src={title} className={classes.title} alt="title" /> */}
      <Container className={classes.containerRoot}>
        <div className="headerFrameRoot">
          <div className="logo">
            <img src={logo} alt="logo" />
          </div>
          <div className="heading">
            <img src={title} alt="title" />
          </div>
          <div className="trophy">
            <img src={trophy} alt="Trophy" />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default LeaderboardHeader;
