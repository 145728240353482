export class RuleContent {
  title: string;
  logo: string;
  rules: Array<string>;

  constructor(title: string, logo: string) {
    this.logo = logo;
    this.title = title;
    this.rules = new Array<string>();
  }
}
