import * as actionTypes from "./actions";

export function typedAction<T extends string>(type: T): { type: T };
export function typedAction<T extends string, P extends any>(
  type: T,
  payload: P
): { type: T; payload: P };
export function typedAction(type: string, payload?: any) {
  return { type, payload };
}

export interface IAlertPayload {
  message: string;
  open: boolean;
}

export type AppState = {
  QrCode: string;
  GameCode: string;
  Alert: IAlertPayload;
};

export const setQrCode = (qrCode: string) => {
  return typedAction(actionTypes.SET_QR_CODE, qrCode);
};

export const setGameCode = (gameCode: string) => {
  return typedAction(actionTypes.SET_GAME_CODE, gameCode);
};

export const setAlert = (message: string) => {
  return typedAction(actionTypes.SET_ALERT, message);
};

export const removeAllItems = () => {
  return typedAction(actionTypes.REMOVE_ALL_ITEMS);
};

export type Action = ReturnType<
  typeof setQrCode | typeof setGameCode | typeof setAlert
>;

const initialState: AppState = {
  QrCode: "",
  GameCode: "",
  Alert: { message: "", open: false } as IAlertPayload,
};

export function reducer(state = initialState, action: Action): AppState {
  switch (action.type) {
    case actionTypes.SET_QR_CODE: {
      return { ...state, QrCode: action.payload };
    }
    case actionTypes.SET_GAME_CODE: {
      return { ...state, GameCode: action.payload };
    }
    case actionTypes.SET_ALERT: {
      let alert = { ...state.Alert };
      alert.message = action.payload;
      alert.open = alert.message ? true : false;
      return { ...state, Alert: alert };
    }
    default:
      return state;
  }
}

export default reducer;
