class LocationFoodOrderService {
  public get = (location: string): string => {
    let londonWestEnd = "https://www.mryum.com/swingers-westend/dine-in";
    let londonCity = "https://www.mryum.com/swingers/dine-in";
    let londonWestEndExt =
      "https://www.mryum.com/swingers-westend-extension/dine-in";
    let dc =
      "https://www.toasttab.com/swingers-dc-crazy-golf-133-new-hampshire-ave/order-and-pay/Swingers/";
    //let londonWestEnd = "https://swingerstrackntracesauks.blob.core.windows.net/menus/WE-Menu-DEC-digital_02-12.pdf";
    //let londonCity = "https://swingerstrackntracesauks.blob.core.windows.net/menus/CITY-Menu-DEC-digital_02-12.pdf";
    switch (location) {
      case "2951be75-85ae-436d-9984-db2d1416a8d6": {
        return londonWestEnd;
      }
      case "f2436ded-da9e-4eb3-aa2e-c5e2603a3821": {
        return londonWestEndExt;
      }
      case "4fb397b1-7f24-450e-a46d-d4c89fbd41ed": {
        return londonCity;
      }
      case "5996e9d5-a611-49ea-af47-5ef3f22bf91b": {
        return dc;
      }
      default: {
        return "";
      }
    }
  };
}
export default LocationFoodOrderService;
