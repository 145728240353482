import { withStyles, createStyles } from "@material-ui/styles";
import { Theme, TableRow } from "@material-ui/core";

const ContrastStyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.secondary.light,
      },
      "&:nth-of-type(even)": {
        backgroundColor: theme.palette.background.default,
      },
    },
  })
)(TableRow);

export default ContrastStyledTableRow;
