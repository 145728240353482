export const Routes = {
  qr: "qr",
  TrackAndTrace: "TrackAndTrace",
  GamesOverview: "GamesOverview",
  GameSelection: "GameSelection",
  PlayCrazyGolf: "PlayCrazyGolf",
  FeedbackForm: "FeedbackForm",
  Information: "Information",
  Rules: "Rules",
  Admin: "Admin",
  Leaderboard: "Leaderboard",
  GamesManagement: "GamesManagement",
};
