import React from "react";
import { Button, ButtonProps, Theme } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

const styles: (theme: Theme) => any = () => {
  return {
    root: {
      margin: "15px",
      borderRadius: "0",
      boxShadow: "none",
      textDecoration: "none",
      letterSpacing: "2px",
      "&.MuiButton-containedSecondary": {
        color: "#FFFAE7",
        "&:before": {
          borderColor: "transparent #BCA25F transparent transparent",
        },
        "&:after": {
          borderColor: "transparent transparent transparent #BCA25F",
        },
        "&:hover": {
          boxShadow: "none",
          "&:before": {
            borderColor:
              "transparent rgb(131, 113, 66) transparent transparent ",
          },
          "&:after": {
            borderColor:
              "transparent transparent transparent rgb(131, 113, 66)",
          },
        },
      },
      "&.MuiButton-containedSizeLarge": {
        fontSize: "0.95rem",
        "&:before": {
          borderWidth: "21px 15px 21px 0",
        },
        "&:after": {
          borderWidth: "21px 0 21px 15px",
        },
      },
      "&:hover": {
        boxShadow: "none",
        "&:before": {
          borderColor: "transparent rgb(14, 31, 39) transparent transparent ",
        },
        "&:after": {
          borderColor: "transparent transparent transparent rgb(14, 31, 39)",
        },
      },
      "&:before,&:after": {
        outline: "none",
        boxSizing: "border-box",
        transition: ".3s",
        content: '""',
        display: "block",
        position: "absolute",
        borderStyle: "solid",
        top: 0,
      },
      "&:before": {
        width: 0,
        height: 0,
        left: "-15px",
        borderWidth: "18px 15px 18px 0",
        borderColor: "transparent #162d39 transparent transparent",
      },
      "&:after": {
        width: 0,
        height: 0,
        right: "-15px",
        borderWidth: "18px 0 18px 15px",
        borderColor: "transparent transparent transparent #162d39",
      },
    },
  };
};

const HexButton = (props: ButtonProps) => {
  const { className } = props;
  const classes = props.classes || {};
  return <Button {...props} className={`${className} ${classes.root}`} />;
};

export default withStyles(styles)(HexButton);
