import { withStyles } from "@material-ui/styles";
import { Theme, createStyles, TableCell } from "@material-ui/core";

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      // backgroundColor: theme.palette.primary.main,
      color: theme.palette.secondary.main,
      fontFamily: "'Oswald', sans-serif",
      letterSpacing: "1.5px",
      border: "0.5px solid " + theme.palette.secondary.main,
      borderLeft: "0px",
      position: "relative",
      fontSize: "1rem",
      padding: "5px 16px",

      "&:last-child": {
        borderRight: "0px",
      },
    },
    body: {
      color: theme.palette.primary.main,
      // fontFamily: "arial",
      border: "0.5px solid " + "#bea361",
      padding: "5px 16px",
    },
  })
)(TableCell);

export default StyledTableCell;
