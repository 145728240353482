import React, { useState, useEffect } from "react";
import "./Leaderboard.css";
import {
  Container,
  makeStyles,
  createStyles,
  // Card,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { useParams } from "react-router-dom";
import { LocationApi } from "../../../services/LocationApi";
import { Player } from "../../../models/CrazyGolf/Player";
import { LocationModel } from "../../../models/CrazyGolf/LocationModel";
import theme from "../../../assets/theme/theme";
import { connect } from "react-redux";
import StyledTableCell from "../../GenericElements/Table/StyledTableCell";
import StyledTableRow from "../../GenericElements/Table/StyledTableRow";
// import title from "../../../assets/icons/leaderboard/LeaderboardTitle.svg";
import LeaderboardHeader from "./LeaderboardHeader/LeaderboardHeader";
import LeaderboardBg from "../../../assets/icons/leaderboard/bg-leaderboard.png";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      // marginTop: "-32px",
      // borderRight: "16px solid" + theme.palette.primary.main,
      //borderBottom: "16px solid" + theme.palette.primary.main,
      // borderLeft: "16px solid" + theme.palette.primary.main,
      //height: "calc(100% + 48px)",
      // height: "105%",
      // minHeight: "105%",
      // background: `url("${LeaderboardBg}")`,
    },
    containerRoot: {
      // marginTop: "4%",
      marginBottom: "10px",
      display: "flex",
      minWidth: "100%",
    },
    // leftbar: {
    //   position: "absolute",
    //   left: 0,
    //   top: 0,
    //   width: "16px",
    //   height: "100%",
    //   minHeight: "100%",
    //   backgroundColor: theme.palette.primary.main,
    // },
    // rightbar: {
    //   position: "absolute",
    //   right: 0,
    //   top: 0,
    //   width: "16px",
    //   height: "100%",
    //   minHeight: "100%",
    //   backgroundColor: theme.palette.primary.main,
    // },
    table: {
      padding: "0 16px",
      fontSize: "1.1rem",
    },
    tableSize: {
      minHeight: "100%",
    },
    position: {
      color: theme.palette.primary.main,
      fontSize: "2rem",
    },
    header: {
      // position: "absolute",
      // top: "0",
      zIndex: 2000,
      width: "100%",
    },
    titleRoot: {
      backgroundColor: theme.palette.primary.main,
      borderRadius: "0",
      border: "0px solid " + theme.palette.primary.main,
      textAlign: "center",
    },
    title: {
      height: "6rem",
      margin: "0rem 2rem",
      marginLeft: "3.5rem",
    },
    // fillerSpacer: {
    //   width: "100%",
    //   height: "17px",
    //   position: "absolute",
    //   bottom: 0,
    //   left: 0,
    //   marginBottom: 0,
    //   backgroundColor: theme.palette.primary.main,
    // },
    textSize: {
      fontSize: "2rem",
    },
  })
);

interface ILeaderboardParams {
  locationId: string;
}

const Leaderboard = () => {
  let classes = useStyles();

  const leaderBoardApi = new LocationApi();
  const [topPlayers, setTopPlayers] = useState(new Array<Player>());
  const [topPlayersTwo, setTopPlayersTwo] = useState(new Array<Player>());
  const [, setLocationDetails] = useState({} as LocationModel);
  const params: ILeaderboardParams = useParams();

  useEffect(() => {
    async function getLeaderboard(locationId: string) {
      await leaderBoardApi.getTopForLocation(locationId).then((response) => {
        const firstHalf = response.splice(0, 10);
        const secondHalf = response.splice(-5);
        setTopPlayers(firstHalf);
        setTopPlayersTwo(secondHalf);
      });
    }

    getLeaderboard(params.locationId);

    const interval = setInterval(() => {
      getLeaderboard(params.locationId);
    }, 30000);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  useEffect(() => {
    async function getLocationDetails(locationId: string) {
      leaderBoardApi
        .getLocation(locationId)
        .then((response) => {
          setLocationDetails(response);
        })
        .catch(() => {});
    }
    getLocationDetails(params.locationId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  useEffect(() => {
    document.body.className = "leaderboard";
  });

  return (
    <div className={classes.root}>
      {/* <div className={classes.toolbar}>
        <div className={classes.headerFrameRoot}>
          <img
            src={logo}
            className={classes.logo}
            alt="logo"
            // onClick={goToRoot}
          />
          <img src={title} className={classes.title} alt="title" />
        </div>
      </div> */}
      <div className={classes.header}>
        <LeaderboardHeader></LeaderboardHeader>
      </div>
      {/* <Card variant="outlined" className={classes.titleRoot}>
        <img src={title} className={classes.title} alt="title" />
      </Card> */}
      <Container className={classes.containerRoot}>
        <TableContainer className={classes.table}>
          <Table aria-label="customized table" className={classes.tableSize}>
            <TableHead>
              <TableRow>
                <StyledTableCell align="center">
                  <span>Position</span>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <span>Player</span>
                </StyledTableCell>
                {/* <StyledTableCell align="center">Par</StyledTableCell> */}
                <StyledTableCell align="center">
                  <span>Total</span>
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {topPlayers.map((row, index) => (
                <StyledTableRow key={row.id}>
                  <StyledTableCell
                    align="center"
                    component="th"
                    scope="row"
                    className={classes.position}
                  >
                    {index + 1}
                  </StyledTableCell>
                  <StyledTableCell className={classes.textSize} align="center">
                    {row.name}
                  </StyledTableCell>
                  {/* <StyledTableCell align="center">{row.score}</StyledTableCell> */}
                  <StyledTableCell className={classes.textSize} align="center">
                    {row.score}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {/* <Card variant="outlined" className={classes.cardRoot}>
        <div>
          <Avatar
            src={new TrackLogoService().get(locationDetails.name)}
            className={classes.rulesLogo}
          />
          <Typography variant="h5" className={classes.title}>
            {locationDetails.name}
          </Typography>
        </div>
        {topPlayers.map((player, index) => (
          <CardContent className={classes.cardContent} key={index}>
            <Avatar className={classes.ruleBulletNumber}>{index + 1}</Avatar>
            <div className={classes.itemDetails}>
              <div>
                <Typography component="span" variant="h4" color="textPrimary">
                  {player.score} - {player.name}
                </Typography>
              </div>
            </div>
          </CardContent>
        ))}
      </Card> */}
        {/* <TableContainer className={classes.table}>
          <Table aria-label="customized table" className={classes.tableSize}>
            <TableHead>
              <TableRow>
                <StyledTableCell align="center">Pos</StyledTableCell>
                <StyledTableCell align="center">Player</StyledTableCell>
                <StyledTableCell align="center">Total</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {topPlayersTwo.map((row, index) => (
                <StyledTableRow key={row.id}>
                  <StyledTableCell
                    align="center"
                    component="th"
                    scope="row"
                    className={classes.position}
                  >
                    {index + 6}
                  </StyledTableCell>
                  <StyledTableCell className={classes.textSize} align="center">
                    {row.name}
                  </StyledTableCell>

                  <StyledTableCell className={classes.textSize} align="center">
                    {row.score}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer> */}
      </Container>
      {/* <div className={classes.fillerSpacer}>&nbsp;</div> */}
      {/* <div className={classes.leftbar}>&nbsp;</div>
      <div className={classes.rightbar}>&nbsp;</div> */}
    </div>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Leaderboard);
