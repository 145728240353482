import React from "react";
import { Grid, Card, Typography, makeStyles } from "@material-ui/core";
import theme from "../../../../assets/theme/theme";
import { Player } from "../../../../models/CrazyGolf/Player";
import HelpIcon from "@material-ui/icons/Help";

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    width: "100%",
  },
  item: {
    flex: "1 1 0px",
    maxWidth: "20%",
  },
  cardTile: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  headerTile: {
    height: "50px",
    borderRadius: "0",
    border: "0.5px solid " + theme.palette.secondary.main,
    backgroundColor: theme.palette.primary.main,
  },
  halfHeaderTile: {
    height: "25px",
    borderRadius: "0",
    border: "0.5px solid " + theme.palette.secondary.main,
    backgroundColor: theme.palette.primary.main,
    display: "flex",
    flexDirection: "row",
  },
  transparentBackground: {
    height: "25px",
    backgroundColor: "transparent",
    margin: "0 10px",
  },
  scoreTile: {
    height: "50px",
    borderRadius: "0",
    border: "0.5px solid " + theme.palette.secondary.main,
    backgroundColor: theme.palette.background.default,
  },
  cardContent: {
    flex: "1 1 0px",
  },
  holeTileValue: {
    flex: "2 1 0",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.background.default,
    height: "100%",
  },
  holeTilePar: {
    flex: "1 1 0px",
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.background.default,
    height: "100%",
  },
  headerFooterTile: {
    fontFamily: theme.typography.h6.fontFamily,
    letterSpacing: "1px",
    textAlign: "center",
    overflow: "hidden",
  },
}));

interface IScorecardHeaderProps {
  players: Array<Player>;
  setShowRules: any;
}

const ScorecardHeader = (props: IScorecardHeaderProps) => {
  const classes = useStyles();
  return (
    <Grid item xs={12}>
      <Grid
        container
        justify="center"
        spacing={0}
        className={classes.container}
      >
        <Grid item className={classes.item}>
          <div>
            <div className={classes.transparentBackground}>
              <HelpIcon color="primary" onClick={props.setShowRules}></HelpIcon>
            </div>
            <Card className={`${classes.halfHeaderTile} ${classes.cardTile}`}>
              <div className={`${classes.cardTile} ${classes.holeTileValue}`}>
                <Typography
                  variant="body2"
                  className={classes.headerFooterTile}
                >
                  Hole
                </Typography>
              </div>
              <div className={`${classes.cardTile} ${classes.holeTilePar}`}>
                <Typography
                  variant="body2"
                  className={classes.headerFooterTile}
                >
                  Par
                </Typography>
              </div>
            </Card>
          </div>
        </Grid>
        {props.players.map((player) => (
          <Grid key={player.id} item className={classes.item}>
            <Card className={`${classes.headerTile} ${classes.cardTile}`}>
              <Typography
                variant="body2"
                color="textSecondary"
                className={classes.headerFooterTile}
              >
                {player.name}
              </Typography>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default ScorecardHeader;
