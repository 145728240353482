import React from "react";
import classes from "./Layout.module.css";
import CssBaseline from "@material-ui/core/CssBaseline";
import Header from "../../components/Header/Header";
import { ThemeProvider } from "@material-ui/core";
import theme from "../../assets/theme/theme";
import AlertSnackBar from "../../components/GenericElements/AlertSnackBar/AlertSnackBar";

const Layout = (props: any) => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Header></Header>
      <main className={classes.Content}>{props.children}</main>
      <AlertSnackBar></AlertSnackBar>
    </ThemeProvider>
  );
};

export default Layout;
