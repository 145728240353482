import React, { useEffect, useState } from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import keycloak from "../../../assets/keycloak/Keycloak";
import { CircularProgress, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  root: {
    textAlign: "center",
    paddingTop: "50%",
  },
}));

const PrivateRoute: React.FC<{
  component: React.FC;
  path: string;
  exact: boolean;
  roles: string[];
}> = (props) => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);

  const roles = props.roles;

  useEffect(() => {
    function getKeycloak() {
      if (keycloak.authenticated) {
        setIsLoading(false);
        return;
      }
      if (roles) {
        keycloak
          .init({ onLoad: "login-required", checkLoginIframe: false })
          .then(() => {
            keycloak.onTokenExpired = () => {
              keycloak.updateToken(200).error(() => {
                console.error("Failed to refresh token " + new Date());
              });
            };
            setIsLoading(false);
          })
          .catch(() => {
            setIsLoading(false);
          });
      }
    }
    getKeycloak();
  }, [roles]);

  const isAuthorized = () => {
    if (keycloak && props.roles) {
      return props.roles.some((r) => {
        const realm = keycloak.hasRealmRole(r);
        const resource = keycloak.hasResourceRole(r);
        return realm || resource;
      });
    }
    return false;
  };

  return isLoading ? (
    <div className={classes.root}>
      <CircularProgress />
    </div>
  ) : isAuthorized() ? (
    <Route path={props.path} exact={props.exact} component={props.component} />
  ) : (
    <Redirect to="/" />
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);
