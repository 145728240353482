import React from "react";
import {
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  Avatar,
  makeStyles,
  Typography,
} from "@material-ui/core";
import theme from "../../../../assets/theme/theme";

const useStyles = makeStyles(() => ({
  root: {
    display: "block",
    margin: "0",
  },
  radioGroup: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    width: "100%",
  },
  ruleBulletNumber: {
    color: theme.palette.primary.main,
    backgroundColor: "transparent",
    border: "1px solid " + theme.palette.primary.main,
  },
  ruleBulletNumberChecked: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.secondary.main,
    border: "1px solid " + theme.palette.primary.main,
  },
  label: {
    color: theme.palette.primary.main,
    justifyContent: "center",
    textAlign: "center",
    paddingTop: "15px",
  },
  radio: {
    position: "relative",
  },
  required: {
    color: "red",
  },
}));

interface IFeedbackFormProps {
  label: string;
  name: string;
  value: string;
  onChange: any;
  error: string | undefined;
  touched: boolean | undefined;
}

const FeedbackFormQuestion = (props: IFeedbackFormProps) => {
  const classes = useStyles();
  return (
    <FormControl component="fieldset" className={classes.root}>
      <FormLabel component="legend" className={classes.label}>
        <Typography
          variant="subtitle1"
          className={props.error && props.touched ? classes.required : ""}
        >
          {props.label}
          {props.error && props.touched ? (
            <span className={classes.required}>&nbsp;*</span>
          ) : null}
        </Typography>
      </FormLabel>
      <RadioGroup
        aria-label={props.label}
        name={props.name}
        className={classes.radioGroup}
        value={props.value}
        onChange={props.onChange}
      >
        <Radio
          value="1"
          icon={<Avatar className={classes.ruleBulletNumber}>1</Avatar>}
          checkedIcon={
            <Avatar className={classes.ruleBulletNumberChecked}>1</Avatar>
          }
          className={classes.radio}
        ></Radio>
        <Radio
          value="2"
          icon={<Avatar className={classes.ruleBulletNumber}>2</Avatar>}
          checkedIcon={
            <Avatar className={classes.ruleBulletNumberChecked}>2</Avatar>
          }
        ></Radio>
        <Radio
          value="3"
          icon={<Avatar className={classes.ruleBulletNumber}>3</Avatar>}
          checkedIcon={
            <Avatar className={classes.ruleBulletNumberChecked}>3</Avatar>
          }
        ></Radio>
        <Radio
          value="4"
          icon={<Avatar className={classes.ruleBulletNumber}>4</Avatar>}
          checkedIcon={
            <Avatar className={classes.ruleBulletNumberChecked}>4</Avatar>
          }
        ></Radio>
        <Radio
          value="5"
          icon={<Avatar className={classes.ruleBulletNumber}>5</Avatar>}
          checkedIcon={
            <Avatar className={classes.ruleBulletNumberChecked}>5</Avatar>
          }
        ></Radio>
      </RadioGroup>
    </FormControl>
  );
};

export default FeedbackFormQuestion;
