import axios from "axios";
import { TrackAndTraceRequest } from "../models/TrackAndTrace/TrackAndTraceRequest";
import { BASE_URL } from "../models/dto/BaseApiDto";

export class TrackAndTraceApi {
  public register = async (
    location: string,
    request: TrackAndTraceRequest
  ): Promise<any> => {
    return await axios
      .post(`${BASE_URL}unsecured/locations/${location}/trackAndTrace`, request)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.error(error);
        return Promise.reject(error);
      });
  };
}
