import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  makeStyles,
  createStyles,
  Card,
  TableHead,
  TableRow,
  TableContainer,
  Paper,
  Table,
  TableBody,
  TableFooter,
  TablePagination,
  Button,
} from "@material-ui/core";
import theme from "../../../assets/theme/theme";
import GamesApi from "../../../services/GamesApi";
import { GameItem } from "../../../models/CrazyGolf/GameItem";
import ContrastStyledTableRow from "../../GenericElements/Table/ContrastStyledTableRow";
import StyledTableCell from "../../GenericElements/Table/StyledTableCell";
import { GamesManagement as GamesManagementModel } from "../../../models/GamesManagement/GamesManagement";
import DeleteIcon from "@material-ui/icons/Delete";
import TablePaginationActions from "../../GenericElements/Table/TablePaginationActions";
import ConfirmationDialog from "../../GenericElements/ConfirmationDialog/ConfirmationDialog";

const useStyles = makeStyles(() =>
  createStyles({
    ruleBulletNumber: {
      color: theme.palette.primary.main,
      backgroundColor: "transparent",
      border: "1px solid " + theme.palette.secondary.main,
    },
    root: {
      borderRadius: "0",
      border: "1px solid " + theme.palette.secondary.main,
      backgroundColor: theme.palette.background.default,
      margin: "0.5em 0",
    },
    center: {
      textAlign: "center",
    },
    title: {
      margin: "15px auto",
      textAlign: "center",
    },
    table: {
      minWidth: 700,
    },
    tableFooter: {
      backgroundColor: theme.palette.background.default,
      color: theme.palette.primary.main,
    },
  })
);

const GamesManagement = () => {
  let classes = useStyles();
  const [gamesManagementData, setGamesManagementData] = useState(
    new GamesManagementModel()
  );
  const gameService = new GamesApi();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [selectedGameItem, setSelectedGameItem] = useState({} as GameItem);

  const getPage = async (newPage: number, numberOfRowsPerPage: number) => {
    setPage(newPage);
    await gameService
      .getAllPaged(newPage, numberOfRowsPerPage)
      .then((response) => {
        response.content.sort((a: GameItem, b: GameItem) => {
          return b.createdDate.getTime() - a.createdDate.getTime();
        });
        setGamesManagementData(response);
      });
  };

  useEffect(() => {
    getPage(0, rowsPerPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangePage = (
    _event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    getPage(newPage, rowsPerPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    getPage(0, +event.target.value);
  };

  const handleRowDelete = async (gameItem: GameItem) => {
    setSelectedGameItem(gameItem);
    setIsDeleteDialogOpen(true);
  };

  const handleConfirmDelete = async () => {
    await gameService.delete(selectedGameItem.id).then(() => {
      let pageNumber =
        gamesManagementData.totalElements === rowsPerPage * page + 1
          ? gamesManagementData.totalPages - 2
          : page;
      pageNumber = pageNumber === -1 ? 0 : pageNumber;
      getPage(pageNumber, rowsPerPage);
      setIsDeleteDialogOpen(false);
    });
  };

  const handleCancelDelete = () => {
    setIsDeleteDialogOpen(false);
  };

  return (
    <Container maxWidth="md">
      <Card variant="outlined" className={classes.root}>
        <Typography variant="h4" className={classes.title}>
          Games Management
        </Typography>
      </Card>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="center">Venue</StyledTableCell>
              <StyledTableCell align="center">Date & Time</StyledTableCell>
              <StyledTableCell align="center">Track</StyledTableCell>
              <StyledTableCell align="center">Players</StyledTableCell>
              <StyledTableCell align="center">Status</StyledTableCell>
              <StyledTableCell align="center">Action</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {gamesManagementData.content.map((row) => (
              <ContrastStyledTableRow key={row.id}>
                <StyledTableCell component="th" scope="row">
                  {row.locationName}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {`${row.createdDate.toLocaleDateString("en-GB", {
                    day: "2-digit",
                    month: "numeric",
                    year: "numeric",
                    hour: "2-digit",
                    minute: "2-digit",
                  })}`}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {row.parentLocationName}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {row.players
                    .map((player) => {
                      return player.name;
                    })
                    .join(", ")}
                </StyledTableCell>
                <StyledTableCell align="center">{row.status}</StyledTableCell>
                <StyledTableCell align="center">
                  <Button onClick={() => handleRowDelete(row)}>
                    <DeleteIcon color="primary"></DeleteIcon>
                  </Button>
                </StyledTableCell>
              </ContrastStyledTableRow>
            ))}
          </TableBody>
          <TableFooter className={classes.tableFooter}>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[15, 30, 60, 90]}
                count={gamesManagementData.totalElements}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { "aria-label": "rows per page" },
                  native: true,
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      <ConfirmationDialog
        isOpen={isDeleteDialogOpen}
        handleCancel={handleCancelDelete}
        handleConfirm={handleConfirmDelete}
        title={`Are you sure you want to delete the game of ${
          selectedGameItem.players
            ? selectedGameItem.players
                .map((player) => {
                  return player.name;
                })
                .join(", ")
            : ""
        }?`}
        message="This action is irreversible."
      ></ConfirmationDialog>
    </Container>
  );
};

export default GamesManagement;
