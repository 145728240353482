import { withStyles, createStyles } from "@material-ui/styles";
import { Theme, TableRow } from "@material-ui/core";

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: "#fcf2d8",

      "&:nth-child(1)": {
        backgroundColor: "#bea361",

        "& th": {
          border: "0px",
        },

        "& td": {
          border: "0px",
        },
      },

      "&:nth-child(2)": {
        backgroundColor: "#d4c296",

        "& th": {
          border: "0px",
        },

        "& td": {
          border: "0px",
        },
      },

      "&:nth-child(3)": {
        backgroundColor: "#e0d3b4",
      },
    },
  })
)(TableRow);

export default StyledTableRow;
