import React from "react";
import {
  Card,
  Typography,
  makeStyles,
  CardContent,
  CardHeader,
  Avatar,
} from "@material-ui/core";
import theme from "../../../../../assets/theme/theme";
import { GameItem } from "../../../../../models/CrazyGolf/GameItem";
import HexButton from "../../../../GenericElements/HexButton/HexButton";
import { useHistory } from "react-router-dom";
import { Routes } from "../../../../../containers/Routes";
import { GameStatus } from "../../../../../models/CrazyGolf/GameStatus";
import TrackLogoService from "../../../../../services/TrackLogoService";

const useStyles = makeStyles(() => ({
  root: {
    borderRadius: "0",
    border: "1px solid " + theme.palette.secondary.main,
    backgroundColor: theme.palette.background.default,
    margin: "15px 0",
  },
  cardTitle: {
    textAlign: "center",
    padding: "5px 0",
    "&.ongoing": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      "& span": {
        fontSize: "1rem",
      },
    },
    "&.finished": {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.primary.contrastText,
      "& span": {
        fontSize: "1rem",
      },
    },
  },
  trackDetails: {
    "&.ongoing": {
      color: theme.palette.primary.main,
    },
    "&.finished": {
      color: theme.palette.secondary.main,
    },
  },
  center: {
    textAlign: "center",
  },
  cardContent: {
    display: "flex",
  },
  itemImage: {
    height: "70px",
    width: "70px",
    margin: "5px",
  },
  itemDetails: {
    flex: "1 1 auto",
  },
}));

interface IGameOverviewItemProps {
  details: GameItem;
}

const GameOverviewItem = (props: IGameOverviewItemProps) => {
  const classes = useStyles();
  let history = useHistory();

  const getClass = () => {
    let cssClass = "";
    if (props.details.status === GameStatus.Ongoing) {
      cssClass = "ongoing";
    } else {
      cssClass = "finished";
    }
    return cssClass;
  };
  const onDetailsClick = () => {
    history.push(`/${Routes.PlayCrazyGolf}/${props.details.id}`);
  };
  return (
    <Card variant="outlined" className={classes.root}>
      <CardHeader
        className={`${classes.cardTitle} ${getClass()}`}
        title={
          props.details.status === GameStatus.Ongoing
            ? "Currently playing"
            : "Past game: " +
              props.details.createdDate.toLocaleDateString("en-GB", {
                day: "2-digit",
                month: "short",
                year: "numeric",
              })
        }
      ></CardHeader>
      <CardContent className={classes.cardContent}>
        <div className={classes.itemDetails}>
          <Typography
            variant="h6"
            className={`${classes.trackDetails} ${getClass()}`}
          >
            {props.details.locationName}
          </Typography>
          <div>
            <Typography
              component="span"
              variant="subtitle1"
              className={`${classes.trackDetails} ${getClass()}`}
            >
              {props.details.parentLocationName}
            </Typography>
          </div>
          <div>
            <Typography
              component="span"
              variant="subtitle2"
              className={`${classes.trackDetails} ${getClass()}`}
            >
              {"Players: " +
                props.details.players
                  .map((player) => {
                    return player.name;
                  })
                  .join(", ")}
            </Typography>
          </div>
        </div>
        <Avatar
          alt={props.details.locationName}
          src={new TrackLogoService().get(props.details.locationName)}
          className={classes.itemImage}
        />
      </CardContent>
      <div className={classes.center}>
        <HexButton
          variant="contained"
          color={
            props.details.status === GameStatus.Ongoing
              ? "primary"
              : "secondary"
          }
          onClick={onDetailsClick}
        >
          {props.details.status === GameStatus.Ongoing
            ? "Go to game"
            : "See Details"}
        </HexButton>
      </div>
    </Card>
  );
};

export default GameOverviewItem;
