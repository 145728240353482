import { GameItem } from "../models/CrazyGolf/GameItem";
import { GameUpdate } from "../models/CrazyGolf/GameUpdate";
import { GameStatus } from "../models/CrazyGolf/GameStatus";
import { Player } from "../models/CrazyGolf/Player";
import AuthorizedApi from "./AuthorizedApi";
import { GamesManagement } from "../models/GamesManagement/GamesManagement";

class GamesApi extends AuthorizedApi {
  public getAll = async (): Promise<Array<GameItem>> => {
    let instance = await this.getInstance();
    return instance
      .get(`secured/games`)
      .then((response) => {
        return response.data.map((value: GameItem) => {
          return this.mapToGameItem(value);
        });
      })
      .catch((error) => {
        console.error(error);
        return Promise.reject(error);
      });
  };

  get = async (id: string): Promise<GameItem> => {
    let instance = await this.getInstance();
    return instance
      .get(`secured/games/${id}`)
      .then((response) => {
        return this.mapToGameItem(response.data);
      })
      .catch((error) => {
        console.error(error);
        return Promise.reject(error);
      });
  };

  public getAllPaged = async (
    pageNumber: number,
    pageSize: number
  ): Promise<GamesManagement> => {
    let instance = await this.getInstance();
    return instance
      .get(`secured/games?page=${pageNumber}&size=${pageSize}`)
      .then((response) => {
        return this.mapToGameManagement(response.data);
      })
      .catch((error) => {
        console.error(error);
        return Promise.reject(error);
      });
  };

  create = async (
    trackId: string,
    players: Array<string>
  ): Promise<GameItem> => {
    let instance = await this.getInstance();
    return instance
      .put(`secured/games`, { locationUuid: trackId, players: players })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
        return Promise.reject(error);
      });
  };

  update = async (
    gameId: string,
    status: GameStatus,
    updates: Array<GameUpdate>
  ) => {
    let instance = await this.getInstance();
    return instance
      .patch(`secured/games/${gameId}/update`, {
        status: status.toUpperCase(),
        updates: updates,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
        return Promise.reject(error);
      });
  };

  delete = async (id: string): Promise<void> => {
    let instance = await this.getInstance();
    return instance
      .delete(`secured/games/${id}/delete`)
      .then(() => {
        return;
      })
      .catch((error) => {
        console.error(error);
        return Promise.reject(error);
      });
  };

  private mapToGameManagement(value: GamesManagement) {
    var gamesManagement = Object.assign(new GamesManagement(), value);
    gamesManagement.content = value.content.map((value: GameItem) => {
      return this.mapToGameItem(value);
    });
    return gamesManagement;
  }

  private mapToGameItem(value: GameItem) {
    var gameItem = Object.assign(new GameItem(), value);
    gameItem.createdDate = new Date(value.createdDate);
    gameItem.players = gameItem.players.map((player) => {
      return Object.assign(new Player(), player);
    });
    return gameItem;
  }
}

export default GamesApi;
