import React from "react";
import {
  Typography,
  makeStyles,
  createStyles,
  Card,
  CardContent,
  Avatar,
  Container,
} from "@material-ui/core";
import theme from "../../../assets/theme/theme";
import HexButton from "../HexButton/HexButton";

const useStyles = makeStyles(() =>
  createStyles({
    confirmationModal: {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 1200,
      backgroundColor: "#ffffff",
      overflowY: "scroll",
    },
    title: {
      fontSize: "1.6rem",
      fontWeight: 700,
      color: "#333",
      textAlign: "center",
      lineHeight: "1.5",
      margin: "1.5em 15px 15px 15px",
      letterSpacing: "1px",
    },
    description: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      marginTop: "3em",
    },
    closeButton: {
      height: "64px",
      width: "100%",
      padding: "0 80px",
    },
    closeConfirmation: {
      boxShadow: "0 2px 28px 0 rgba(0,0,0,0.12)",
      backgroundColor: theme.palette.background.paper,
      position: "fixed",
      bottom: "0",
      right: "0",
      left: "0",
      border: "none",
      zIndex: 100,
    },
    cardContent: {
      padding: "5px 16px",
    },
    center: {
      textAlign: "center",
    },
    root: {
      borderRadius: "0",
      border: "1px solid " + theme.palette.secondary.main,
      backgroundColor: theme.palette.background.default,
    },
    avatarIcon: {
      height: "100px",
      width: "100px",
      margin: "15px auto",
    },
  })
);

interface IFormConfimModalProps {
  playCrazyGolf: any;
  orderFoodAndDrinks: any;
  lineTwo: string;
  lineThree: string;
  icon: string;
}

const FormConfirmModal = (props: IFormConfimModalProps) => {
  const classes = useStyles();
  var onPlayCrazyGolfClick = () => {
    props.playCrazyGolf();
  };

  var onOrderFoodAndDrinksClick = () => {
    props.orderFoodAndDrinks();
  };

  return (
    <Container maxWidth="md">
      <Card variant="outlined" className={classes.root}>
        <div>
          <Typography variant="h1" className={classes.title}>
            Thank you <br />
            {props.lineTwo}
            <br />
            {props.lineThree}
          </Typography>
        </div>
        <div>
          <Avatar src={props.icon} className={classes.avatarIcon} />
        </div>
        <CardContent className={classes.cardContent}></CardContent>
      </Card>
      <div className={classes.center}>
        <HexButton
          variant="contained"
          color="primary"
          onClick={onPlayCrazyGolfClick}
        >
          Play crazy golf
        </HexButton>
      </div>
      <div className={classes.center}>
        <HexButton
          variant="contained"
          color="primary"
          onClick={onOrderFoodAndDrinksClick}
        >
          Order food and drinks
        </HexButton>
      </div>
    </Container>
  );
};

export default FormConfirmModal;
