import React from "react";
import { TransitionProps } from "@material-ui/core/transitions/transition";
import {
  Slide,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  makeStyles,
  Typography,
} from "@material-ui/core";
import theme from "../../../assets/theme/theme";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles(() => ({
  root: {
    "& .MuiPaper-root": {
      backgroundColor: theme.palette.background.default,
    },
  },
  dialogContent: {
    textAlign: "center",
  },
}));

interface IConfirmationDialogProps {
  title?: string;
  message: string;
  isOpen: boolean;
  handleCancel: any;
  handleConfirm: any;
}

const ConfirmationDialog = (props: IConfirmationDialogProps) => {
  const classes = useStyles();

  return (
    <Dialog
      open={props.isOpen}
      TransitionComponent={Transition}
      keepMounted
      onClose={props.handleCancel}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      className={classes.root}
    >
      {props.title ? (
        <DialogTitle id="alert-dialog-slide-title">{props.title}</DialogTitle>
      ) : null}
      <DialogContent className={classes.dialogContent}>
        <Typography>{props.message}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleCancel} color="primary">
          Cancel
        </Button>
        <Button onClick={props.handleConfirm} color="primary">
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
