import { GameStatus } from "./GameStatus";
import { Player } from "./Player";
export class GameItem {
  id!: string;
  parentLocationName!: string;
  locationName!: string;
  trackLogo!: string;
  createdDate!: Date;
  status: GameStatus = GameStatus.Finished;
  players: Array<Player> = new Array<Player>();
  parPerHoleMap: { [id: string]: string } = {};
}
