import React, { useState } from "react";
import {
  TextField,
  Card,
  Typography,
  CardContent,
  FormControlLabel,
  Checkbox,
  Container,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { FormikHelpers, useFormik } from "formik";
import HexButton from "../../GenericElements/HexButton/HexButton";
import { makeStyles } from "@material-ui/styles";
import FormConfirmModal from "../../GenericElements/FormConfirmModal/FormConfirmModal";
import theme from "../../../assets/theme/theme";
import { Routes } from "../../../containers/Routes";
import { TrackAndTraceApi } from "../../../services/TrackAndTraceApi";
import { CookieIdentifiers } from "../../../models/Cookies";
import { useCookies } from "react-cookie";
import { TrackAndTraceRequest } from "../../../models/TrackAndTrace/TrackAndTraceRequest";
import { setAlert, AppState } from "../../../store/reducer";
import { connect } from "react-redux";
import { IAlertSnackBarProps } from "../../GenericElements/AlertSnackBar/AlertSnackBar";
import trackAndTraceIcon from "../../../assets/icons/trackAndTrace/trace-trace-asset.svg";

const useStyles = makeStyles(() => ({
  root: {
    borderRadius: "0",
    border: "1px solid " + theme.palette.secondary.main,
    backgroundColor: theme.palette.background.default,
  },
  center: {
    textAlign: "center",
  },
  cardContent: {
    padding: "5px 16px",
  },
  title: {
    margin: "15px auto",
    textAlign: "center",
  },
  inputLabelColor: {
    color: "currentColor",
  },
  checkBox: {
    color: theme.palette.secondary.main,
  },
  link: {
    color: theme.palette.text.primary,
    fontWeight: 700,
    textDecoration: "unset",
  },
}));

interface TrackAndTraceFormValues {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  keepForAdveertising: boolean;
}

interface ITrackAndTraceFormProps extends IAlertSnackBarProps {}

const TrackAndTraceForm = (props: ITrackAndTraceFormProps) => {
  const classes = useStyles();
  let history = useHistory();
  const [displayForm, setDisplayForm] = useState(true);
  const [cookies] = useCookies([CookieIdentifiers.SwingersCrazyGolf]);

  const onPlayCrazyGolf = () => {
    history.push(`/${Routes.Rules}`);
  };
  const onOrderFoodAndDrinks = () => {
    if (cookies[CookieIdentifiers.SwingersVenueFood]) {
      window.location.href = cookies[CookieIdentifiers.SwingersVenueFood];
    } else {
      props.setAlert(
        "Please scan the QR on table in order to access the menu."
      );
    }
  };

  const trackAndTraceApi = new TrackAndTraceApi();

  const handleFormSubmit = async (values: TrackAndTraceFormValues) => {
    let location = cookies[CookieIdentifiers.SwingersVenue];
    if (location) {
      trackAndTraceApi.register(
        location,
        Object.assign(new TrackAndTraceRequest(), values)
      );
      setDisplayForm(false);
    } else {
      props.setAlert(
        "Please scan the QR at the entrance before submitting the form"
      );
    }
  };

  const validate = (values: TrackAndTraceFormValues) => {
    const errors: any = {};
    if (!values.firstName) {
      errors.firstName = "Required";
    } else if (values.firstName.length > 15) {
      errors.firstName = "Must be 15 characters or less";
    }

    if (!values.lastName) {
      errors.lastName = "Required";
    } else if (values.lastName.length > 20) {
      errors.lastName = "Must be 20 characters or less";
    }

    if (!values.phoneNumber) {
      errors.phoneNumber = "Required";
    } else if (!/^\+?\)?([0-9]{10,})$/i.test(values.phoneNumber)) {
      errors.phoneNumber = "Please enter a valid phone number";
    } else if (values.phoneNumber.length > 15) {
      errors.phoneNumber = "Must be less than 15 digits";
    }

    if (!values.email) {
      errors.email = "Required";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = "Invalid email address";
    }

    return errors;
  };

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      phoneNumber: "",
      email: "",
      keepForAdveertising: false,
    },
    validate,
    onSubmit: async (
      values: TrackAndTraceFormValues,
      { setSubmitting }: FormikHelpers<TrackAndTraceFormValues>
    ) => {
      await handleFormSubmit(values);
      setSubmitting(false);
    },
  });

  const label = (
    <div>
      Sign up to get the latest news from Swingers. We'll always treat your
      personal details with the utmost care. Please see our{" "}
      <a
        href="https://swingers.club/uk/privacy-policy"
        className={classes.link}
      >
        privacy policy
      </a>{" "}
      for more details.
    </div>
  );

  return displayForm ? (
    <Container maxWidth="md">
      <Card variant="outlined" className={classes.root}>
        <div>
          <Typography variant="h5" className={classes.title}>
            Track and trace
          </Typography>
        </div>
        <CardContent className={classes.cardContent}>
          <form onSubmit={formik.handleSubmit}>
            <TextField
              id="firstName"
              label="First Name"
              helperText={
                formik.errors.firstName && formik.touched.firstName
                  ? formik.errors.firstName
                  : "Enter your first name"
              }
              {...formik.getFieldProps("firstName")}
              fullWidth
              margin="normal"
              InputLabelProps={{
                className: classes.inputLabelColor,
              }}
              variant="outlined"
              error={
                formik.errors.firstName && formik.touched.firstName
                  ? true
                  : false
              }
            />
            <TextField
              id="lastName"
              label="Last Name"
              helperText={
                formik.errors.lastName && formik.touched.lastName
                  ? formik.errors.lastName
                  : "Enter your last name"
              }
              {...formik.getFieldProps("lastName")}
              fullWidth
              margin="normal"
              InputLabelProps={{
                className: classes.inputLabelColor,
              }}
              variant="outlined"
              error={
                formik.errors.lastName && formik.touched.lastName ? true : false
              }
            />
            <TextField
              id="phoneNumber"
              label="Phone Number"
              helperText={
                formik.errors.phoneNumber && formik.touched.phoneNumber
                  ? formik.errors.phoneNumber
                  : "Enter your phone number"
              }
              {...formik.getFieldProps("phoneNumber")}
              fullWidth
              margin="normal"
              InputLabelProps={{
                className: classes.inputLabelColor,
              }}
              variant="outlined"
              error={
                formik.errors.phoneNumber && formik.touched.phoneNumber
                  ? true
                  : false
              }
            />
            <TextField
              id="email"
              label="E-mail"
              helperText={
                formik.errors.email && formik.touched.email
                  ? formik.errors.email
                  : "Enter your e-mail"
              }
              {...formik.getFieldProps("email")}
              fullWidth
              margin="normal"
              InputLabelProps={{
                className: classes.inputLabelColor,
              }}
              variant="outlined"
              error={formik.errors.email && formik.touched.email ? true : false}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={formik.values.keepForAdveertising}
                  {...formik.getFieldProps("keepForAdveertising")}
                  className={classes.checkBox}
                />
              }
              label={label}
              labelPlacement="end"
            />
            <div className={classes.center}>
              <HexButton
                variant="contained"
                color="primary"
                type="submit"
                disabled={formik.isSubmitting}
              >
                Submit
              </HexButton>
            </div>
          </form>
        </CardContent>
      </Card>
    </Container>
  ) : (
    <FormConfirmModal
      lineTwo="For submitting your details to"
      lineThree="our track and trace system"
      playCrazyGolf={onPlayCrazyGolf}
      orderFoodAndDrinks={onOrderFoodAndDrinks}
      icon={trackAndTraceIcon}
    ></FormConfirmModal>
  );
};

const mapStateToProps = (state: AppState) => ({});

const mapDispatchToProps = { setAlert };

type Props = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(TrackAndTraceForm);
