import axios from "axios";
import { FeedbackFormRequest } from "../models/Feedback/FeedbackFormRequest";
import { FeedbackEntry } from "../models/Feedback/FeedbackEntry";
import { BASE_URL } from "../models/dto/BaseApiDto";

export class FeedbackFormService {
  public register = async (
    location: string,
    request: FeedbackFormRequest
  ): Promise<any> => {
    return await axios
      .post(`${BASE_URL}unsecured/locations/${location}/feedback`, request)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.error(error);
        return Promise.reject(error);
      });
  };

  public getFormEntries = (): { [id: string]: FeedbackEntry } => {
    let feedbackFormEntries: { [id: string]: FeedbackEntry } = {};
    feedbackFormEntries["experienceQuestion"] = new FeedbackEntry(
      "How would you rate your overall swingers experience?",
      0
    );

    feedbackFormEntries["serviceQuestion"] = new FeedbackEntry(
      "How did you find the service at swingers?",
      0
    );

    feedbackFormEntries["crazyGolfQuestion"] = new FeedbackEntry(
      "Did you enjoy your round of crazy golf?",
      0
    );
    feedbackFormEntries["foodAndDrinksQuestion"] = new FeedbackEntry(
      "How would you rate the quality of food and drinks?",
      0
    );
    feedbackFormEntries["returnQuestion"] = new FeedbackEntry(
      "How likely are you to return to swingers?",
      0
    );
    return feedbackFormEntries;
  };
}
