import React, { useEffect, useState, Fragment } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import { CookieIdentifiers } from "../../../models/Cookies";
import { Routes } from "../../../containers/Routes";
import { Container, Card, makeStyles, Grid } from "@material-ui/core";
import theme from "../../../assets/theme/theme";
import { GameItem } from "../../../models/CrazyGolf/GameItem";
import GamesApi from "../../../services/GamesApi";
import { GameStatus } from "../../../models/CrazyGolf/GameStatus";
import ScorecardHeader from "./Scorecard/ScorecardHeader";
import ScorecardTotal from "./Scorecard/ScorecardTotal";
import ScorecardHole from "./Scorecard/ScorecardHole";
import { Player } from "../../../models/CrazyGolf/Player";
import ScoreDialog from "./ScoreDialog/ScoreDialog";
import { GameUpdate } from "../../../models/CrazyGolf/GameUpdate";
import { connect } from "react-redux";
import HexButton from "../../GenericElements/HexButton/HexButton";
import ConfirmationDialog from "../../GenericElements/ConfirmationDialog/ConfirmationDialog";
import Rules from "./Rules/Rules";

const useStyles = makeStyles(() => ({
  mainCard: {
    borderRadius: "0",
    border: "0px solid " + theme.palette.secondary.main,
    backgroundColor: "transparent",
    margin: "0.5em 0",
  },
  center: {
    textAlign: "center",
  },
}));

interface IPlayGolf {}

interface IPlayGolfParams {
  gameId: string;
}

const PlayGolf = (props: IPlayGolf) => {
  const classes = useStyles();
  let history = useHistory();
  const params: IPlayGolfParams = useParams();

  const [cookies] = useCookies([CookieIdentifiers.SwingersCrazyGolf]);
  const [game, setGame] = useState(new GameItem());

  const [showRules, setShowRules] = useState(false);
  const [isFinishDialogOpen, setIsFinishDialogOpen] = useState(false);
  const [isEditScoreOpen, setEditScoreOpen] = useState(false);
  const [selectedPlayerTile, setSelectedPlayerTile] = useState({
    player: {} as Player,
    hole: "",
    score: 1,
  });

  const handleClose = () => {
    setEditScoreOpen(false);
    setSelectedPlayerTile({ player: {} as Player, hole: "", score: 1 });
  };

  const handleSaveAndClose = (score: number) => {
    async function updateGame(gameUpdate: GameUpdate) {
      await gameService
        .update(game.id, game.status, [gameUpdate])
        .then((response) => {
          handleClose();
        });
    }

    let gameUpdate = new GameUpdate(
      selectedPlayerTile.player.id,
      selectedPlayerTile.hole,
      score
    );

    updateScorecard(gameUpdate);
    updateGame(gameUpdate);
  };

  const updateScorecard = (gameUpdate: GameUpdate) => {
    let player = game.players.find(
      (x) => x.id === gameUpdate.playerId
    ) as Player;
    let initialScore = player.scorePerHoleMap[selectedPlayerTile.hole]
      ? player.scorePerHoleMap[selectedPlayerTile.hole]
      : 0;
    player.scorePerHoleMap[selectedPlayerTile.hole] = gameUpdate.score;
    player.score += gameUpdate.score - initialScore;
    setGame(game);
  };

  const gameService = new GamesApi();
  const onTileClick = (hole: string, player: Player) => {
    if (game.status === GameStatus.Ongoing) {
      setEditScoreOpen(true);
      let initialScore = player.scorePerHoleMap[hole]
        ? player.scorePerHoleMap[hole]
        : 1;
      setSelectedPlayerTile({ player, hole, score: initialScore });
    }
  };

  const finishGame = () => {
    setIsFinishDialogOpen(true);
  };

  const handleConfirmFinish = () => {
    async function updateGame() {
      await gameService
        .update(game.id, GameStatus.Finished, [])
        .then((response) => {
          setIsFinishDialogOpen(false);
          history.push(`/${Routes.GamesOverview}`);
        });
    }

    updateGame();
  };

  const handleCancelFinish = () => {
    setIsFinishDialogOpen(false);
  };

  useEffect(() => {
    async function getGame(id: string) {
      await gameService.get(id).then((response) => {
        setGame(response);
      });
    }

    if (params.gameId) {
      getGame(params.gameId);
    } else {
      history.push(`/${Routes.GamesOverview}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, cookies, params]);

  const showScorecard = () => {
    setShowRules(false);
  };

  return (
    <Container maxWidth="md">
      {showRules ? (
        <Rules handleContinue={showScorecard}></Rules>
      ) : (
        <Fragment>
          <Card variant="outlined" className={classes.mainCard}>
            <Grid container spacing={0}>
              <ScorecardHeader
                players={game.players}
                setShowRules={() => setShowRules(true)}
              ></ScorecardHeader>
              {Object.keys(game.parPerHoleMap).map((hole, index) => (
                <ScorecardHole
                  key={index}
                  players={game.players}
                  hole={hole}
                  par={game.parPerHoleMap[hole]}
                  onTileClick={onTileClick}
                ></ScorecardHole>
              ))}
              <ScorecardTotal players={game.players}></ScorecardTotal>
            </Grid>
          </Card>
          {game.status === GameStatus.Ongoing ? (
            <React.Fragment>
              <div className={classes.center}>
                <HexButton
                  variant="contained"
                  color="primary"
                  onClick={finishGame}
                >
                  Finish Game
                </HexButton>
              </div>
              <ScoreDialog
                hole={selectedPlayerTile.hole}
                player={selectedPlayerTile.player}
                initialScore={selectedPlayerTile.score}
                isOpen={isEditScoreOpen}
                handleClose={handleClose}
                handleSaveAndClose={handleSaveAndClose}
              ></ScoreDialog>
            </React.Fragment>
          ) : null}
          <ConfirmationDialog
            isOpen={isFinishDialogOpen}
            handleCancel={handleCancelFinish}
            handleConfirm={handleConfirmFinish}
            title="Are you sure you want to finish the current game?"
            message="This action is irreversible and no further changes will be allowed."
          ></ConfirmationDialog>
        </Fragment>
      )}
    </Container>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(PlayGolf);
