import { Player } from "../models/CrazyGolf/Player";
import { LocationModel } from "../models/CrazyGolf/LocationModel";
import { BASE_URL } from "../models/dto/BaseApiDto";
import axios from "axios";

export class LocationApi {
  public getTopForLocation = async (
    location: string
  ): Promise<Array<Player>> => {
    return await axios
      .get(`${BASE_URL}unsecured/locations/${location}/leaderboard`)
      .then((response) => {
        return response.data.map((player: Player) => {
          return Object.assign(new Player(), player);
        });
      })
      .catch((error) => {
        console.error(error);
        return Promise.reject(error);
      });
  };

  getLocation = async (locationId: string) => {
    return await axios
      .get(`${BASE_URL}unsecured/locations/${locationId}`)
      .then((response) => {
        let location = Object.assign(new LocationModel(), response.data);
        return location;
      })
      .catch((error) => {
        console.error(error);
        return Promise.reject(error);
      });
  };

  getVenueLocations = async (): Promise<Array<LocationModel>> => {
    return await axios
      .get(`${BASE_URL}unsecured/locations/parents`)
      .then((response) => {
        let locations = response.data.map((location: LocationModel) => {
          return Object.assign(new LocationModel(), location);
        });
        return locations;
      })
      .catch((error) => {
        console.error(error);
        return Promise.reject(error);
      });
  };

  getGameLocations = async (venueQr: string) => {
    return await axios
      .get(`${BASE_URL}unsecured/locations/${venueQr}/children`)
      .then((response) => {
        let locations = response.data.map((location: LocationModel) => {
          return Object.assign(new LocationModel(), location);
        });
        return locations;
      })
      .catch((error) => {
        console.error(error);
        return Promise.reject(error);
      });
  };
}
