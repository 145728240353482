import React, { Fragment, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { AppState, setQrCode } from "../../../store/reducer";
import { connect } from "react-redux";
import { useCookies } from "react-cookie";
import { CookieIdentifiers } from "../../../models/Cookies";
import LocationFoodOrderService from "../../../services/LocationFoodOrderService";

interface IQrPageProps {
  qrCode: string;
  setQrCode: any;
}

interface IQrPageParams {
  qrcode: string;
  redirectPage: string;
}

const QrPage = (props: IQrPageProps) => {
  let history = useHistory();
  const params: IQrPageParams = useParams();
  let setQrCode = props.setQrCode;
  const [, setCookie] = useCookies([CookieIdentifiers.SwingersCrazyGolf]);

  useEffect(() => {
    if (params.qrcode) {
      setQrCode(params.qrcode);

      let nextDay = new Date();
      nextDay.setUTCDate(nextDay.getDate() + 1);
      nextDay.setUTCHours(3, 0, 0, 0);
      let expirationDate = new Date(nextDay);
      setCookie(CookieIdentifiers.SwingersVenue, params.qrcode, {
        path: "/",
        expires: expirationDate,
        maxAge: 43200,
      });

      let orderFoodLink = new LocationFoodOrderService().get(params.qrcode);
      if (orderFoodLink) {
        setCookie(CookieIdentifiers.SwingersVenueFood, orderFoodLink, {
          path: "/",
          expires: expirationDate,
          maxAge: 43200,
        });
      }

      if (params.redirectPage) {
        history.push(`/${params.redirectPage}`);
      } else {
        history.push("/");
      }
    }
  }, [setQrCode, history, params, setCookie]);

  return <Fragment></Fragment>;
};

const mapStateToProps = (state: AppState) => ({
  qrCode: state.QrCode,
});

const mapDispatchToProps = { setQrCode };

export default connect(mapStateToProps, mapDispatchToProps)(QrPage);
