import React from "react";
import "./App.css";
import Layout from "../hoc/Layout/Layout";
import NoMatch from "../components/NoMatch/NoMatch";
import MainPage from "../components/Unauthorized/MainPage/MainPage";
import { Switch, Route } from "react-router-dom";
import QrPage from "../components/Unauthorized/QRPage/QrPage";
import TrackAndTraceForm from "../components/Unauthorized/TrackAndTrace/TrackAndTraceForm";
import PlayGolf from "../components/Customer/PlayGolf/PlayGolf";
import FeedbackForm from "../components/Unauthorized/FeedbackForm/FeedbackForm";
import Rules from "../components/Customer/PlayGolf/Rules/Rules";
import { Routes } from "./Routes";
import Information from "../components/Unauthorized/Information/Information";
import GameSelection from "../components/Customer/PlayGolf/GameSelection/GameSelection";
import GamesOverview from "../components/Customer/PlayGolf/GamesOverview/GamesOverview";
import PrivateRoute from "../components/GenericElements/PrivateRoute/PrivateRoute";
import LeaderboardLocations from "../components/Unauthorized/Leaderboard/LeaderboardLocations/LeaderboardLocations";
import GamesManagement from "../components/Admin/GamesManagement/GamesManagement";
import { Roles } from "./Roles";
import AdminPage from "../components/Admin/AdminPage";
import Leaderboard from "../components/Unauthorized/Leaderboard/Leaderboard";

function App() {
  return (
    <Layout>
      <Switch>
        <Route exact path="/" component={MainPage} />
        <Route
          path={`/${Routes.qr}/:qrcode/:redirectPage?`}
          component={QrPage}
        />
        <Route
          path={`/${Routes.TrackAndTrace}`}
          component={TrackAndTraceForm}
        />
        <PrivateRoute
          roles={[Roles.Customer]}
          path={`/${Routes.GamesOverview}`}
          component={GamesOverview}
          exact
        />
        <PrivateRoute
          roles={[Roles.Customer]}
          path={`/${Routes.GameSelection}`}
          component={GameSelection}
          exact
        />
        <PrivateRoute
          roles={[Roles.Customer]}
          path={`/${Routes.GameSelection}/:gameCode`}
          component={GameSelection}
          exact
        />
        <PrivateRoute
          roles={[Roles.Customer]}
          path={`/${Routes.PlayCrazyGolf}/:gameId`}
          component={PlayGolf}
          exact
        />
        <Route path={`/${Routes.FeedbackForm}`} component={FeedbackForm} />
        <Route path={`/${Routes.Information}`} component={Information} />
        <Route path={`/${Routes.Rules}`} component={Rules} />
        <PrivateRoute
          roles={[Roles.Admin]}
          path={`/${Routes.Admin}`}
          component={AdminPage}
          exact
        />
        <Route
          path={`/${Routes.Leaderboard}`}
          component={LeaderboardLocations}
          exact
        />
        <Route
          path={`/${Routes.Leaderboard}/:locationId`}
          component={Leaderboard}
          exact
        />
        <PrivateRoute
          roles={[Roles.Admin]}
          path={`/${Routes.GamesManagement}`}
          component={GamesManagement}
          exact
        />
        <Route component={NoMatch} />
      </Switch>
    </Layout>
  );
}

export default App;
