import React from "react";
import { Container, Avatar } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import HexButton from "../GenericElements/HexButton/HexButton";
import { Routes } from "../../containers/Routes";
import CrazyGolfIcon from "../../assets/icons/home/crazyGolfIcon.svg";
import FeedbackIcon from "../../assets/icons/home/feedbackIcon.svg";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      height: "100%",
      alignItems: "center",
    },
    container: {
      display: "flex",
      height: "80%",
      minHeight: "400px",
      padding: "3rem 16px 16px 16px",
      width: "350px",
      justifyContent: "space-around",
      flexDirection: "column",
    },
    itemImage: {
      height: "60px",
      width: "60px",
      marginRight: "15px",
    },
    optionContent: {
      display: "flex",
      alignItems: "center",
    },
  })
);

const AdminPage = () => {
  const classes = useStyles();
  let history = useHistory();

  const onLeaderboardClick = () => {
    history.push(`/${Routes.Leaderboard}`);
  };
  const onGamesManagementClick = () => {
    history.push(`/${Routes.GamesManagement}`);
  };

  return (
    <div className={classes.root}>
      <Container className={classes.container}>
        <div className={classes.optionContent}>
          <Avatar
            alt="Feedback"
            src={FeedbackIcon}
            className={classes.itemImage}
          />
          <HexButton
            variant="contained"
            color="primary"
            onClick={onLeaderboardClick}
            fullWidth={true}
            size="large"
          >
            Leaderboard
          </HexButton>
        </div>
        <div className={classes.optionContent}>
          <Avatar
            alt="Crazy Golf Management"
            src={CrazyGolfIcon}
            className={classes.itemImage}
          />
          <HexButton
            variant="contained"
            color="primary"
            onClick={onGamesManagementClick}
            fullWidth={true}
            size="large"
          >
            Games Management
          </HexButton>
        </div>
      </Container>
    </div>
  );
};

export default AdminPage;
