import { FeedbackEntry } from "./FeedbackEntry";

export class FeedbackFormRequest {
  entries: Array<FeedbackEntry>;
  additionalInfo: string | undefined;

  constructor() {
    this.entries = new Array<FeedbackEntry>();
  }
}
