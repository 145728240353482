import BigWheelIcon from "../assets/icons/golf/crazy-golf_BIG-WHEEL.svg";
import HelterSkelterIcon from "../assets/icons/golf/crazy-golf_HELTER-SKELTER.svg";
import LighthouseIcon from "../assets/icons/golf/crazy-golf_LIGHTHOUSE.svg";
import WindmillIcon from "../assets/icons/golf/crazy-golf_WINDMILL.svg";
import ClockTowerIcon from "../assets/icons/golf/DC_course_icons_badge_clocktower.svg";
import WaterWheelIcon from "../assets/icons/golf/DC_course_icons_badge_waterwheel.svg";
import MeteoriteIcon from "../assets/icons/golf/crazy-golf_METEORITE.svg";

class TrackLogoService {
  public get = (location: string): string => {
    switch (location) {
      case "Helter Skelter": {
        return HelterSkelterIcon;
      }
      case "Big Wheel": {
        return BigWheelIcon;
      }
      case "Lighthouse": {
        return LighthouseIcon;
      }
      case "Windmill": {
        return WindmillIcon;
      }
      case "Clocktower": {
        return ClockTowerIcon;
      }
      case "Waterwheel": {
        return WaterWheelIcon;
      }
      case "Meteorite": {
        return MeteoriteIcon;
      }
      default: {
        return "";
      }
    }
  };
}
export default TrackLogoService;
