import axios from "axios";
import { BASE_URL } from "../models/dto/BaseApiDto";
import { AxiosInstance } from "axios";
import { KeycloakInstance } from "keycloak-js";
import keycloak from "../assets/keycloak/Keycloak";

class AuthorizedApi {
  private keycloakInstance: KeycloakInstance;

  constructor() {
    this.keycloakInstance = keycloak;
  }

  getInstance = async (): Promise<AxiosInstance> => {
    const axiosInstance = axios.create({ baseURL: BASE_URL });
    await this.checkTokenForRefresh();
    let authToken = this.keycloakInstance.token;
    axios.defaults.headers.common["Authorization"] = `Bearer ${authToken}`;
    axiosInstance.interceptors.request.use(function (config: any) {
      config.headers.Authorization = `Bearer ${authToken}`;
      return config;
    });

    return axiosInstance;
  };

  private async checkTokenForRefresh(): Promise<void> {
    if (this.keycloakInstance.isTokenExpired()) {
      await this.keycloakInstance.updateToken(200).catch(() => {
        console.error("Failed to refresh token " + new Date());
      });
    }
  }
}

export default AuthorizedApi;
